import React, { useState, useRef, useEffect, useContext } from "react";
import { Box, CircularProgress, Grid, IconButton, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import axios from "axios";
import Divider from "@mui/material/Divider";
import TransactionExecutionLogs from "./transactionExecutionLogs";
import {
  BASE_URL,
  GET_TRANSACTION_REPORT,
} from "../../../helpers/adminApiStrings";
import moment from "moment";
const { Parser } = require("@json2csv/plainjs");
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "black",
  display: "flex",
}));

const TransactionExecutionReport = function (props) {
  const [transactions, setTransactions] = useState([]);
  const [transactionLoading, setTransactionsLoading] = useState(false);
  const { platformType, setTxnHistoryLoaded } = props;
  const [dataRangeby, setDataRangeby] = useState("Last Week");
  const getTransactionData = () =>
    axios
      .post(BASE_URL + "/" + platformType + GET_TRANSACTION_REPORT, { dataRangeby })
      .then((res) => {
        if (res) {
          setTransactionsLoading(false);
          setTransactions(res.data);
          setTransactionsLoading(true);
          setTxnHistoryLoaded(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  function downloadReport(transactions, date) {
    const parser = new Parser({});
    const csv = parser.parse(transactions);
    var dataStr = "data:text/csv;charset=utf-8," + csv;
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr.toString().replaceAll("#", "%23"));
    downloadAnchorNode.setAttribute(
      "download",
      `transaction_report_${date}` + ".csv"
    );
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }
  const switchTxnHistoryData = (stringParam) => {
    if (stringParam == "Last Week") {
      setDataRangeby(stringParam)
    }
    else if (stringParam == "Last 30 Days") {
      setDataRangeby(stringParam)
    }
    else {
      setDataRangeby(stringParam)
    }
  }
  useEffect(() => {
    setTransactionsLoading(false);
    setTxnHistoryLoaded(true);
    getTransactionData();
  }, [platformType, dataRangeby]);
  return transactionLoading == false ? (
    <Box sx={{ mt: 2, height: "78vh", width: "50%" }}>
      <Item
        style={{
          backgroundColor: "white",
          fontWeight: "bold",
          display: "flex",
          flexFlow: "column",
          height: "78vh",
          padding: "1% 2%",
          width: "95.5%",
        }}
      >
        <h2>
          <CircularProgress disableShrink size={50} />
          <br></br>Loading...
        </h2>
      </Item>
    </Box>
  ) : (
    <>
      <Box sx={{ mt: 2, height: "78vh", width: "50%" }}>
        <Item
          style={{
            flexGrow: 1,
            display: "flex",
            backgroundColor: "white",
            fontWeight: "bold",
            flexFlow: "column",
            height: "78vh",
            padding: "1% 2%",
            width: "95.5%",
          }}
        >
          <p
            style={{
              margin: "auto",
              padding: "20px",
              display: "block",
              fontSize: "20px",
            }}
          >
            Transaction Execution History -{" "}
            {platformType == "TU" ? "Tradeultra" : "Century"}
            <Grid>
              <CustomButton dataRangeby={dataRangeby} displayString={"Last Week"} customFunction={() => { switchTxnHistoryData("Last Week") }} />
              <CustomButton dataRangeby={dataRangeby} displayString={"Last 30 Days"} customFunction={() => { switchTxnHistoryData("Last 30 Days") }} />
              <CustomButton dataRangeby={dataRangeby} displayString={"Last Year"} customFunction={() => { switchTxnHistoryData("Last Year") }} />
            </Grid>
          </p>
          <Divider
            sx={{ width: "70%", margin: "auto", backgroundColor: "#ffd700" }}
          />
          <Grid
            container
            spacing={2}
            sx={{
              height: "70vh",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "0.7em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#ffffff",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "black",
                borderRadius: 2,
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#ffd700",
              },
              marginTop: "10px",
            }}
          >
            {transactions.length != 0 &&
              transactions?.map(function (transaction, index) {
                return (
                  <Grid
                    key={index}
                    item
                    xs={1.5}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "250px",
                    }}
                  >
                    <Item sx={{ display: "block" }}>
                      <div
                        style={{
                          backgroundColor: "#111111",
                          color: "white",
                          padding: "1px",
                        }}
                      >
                        <p style={{ margin: "5px" }}>
                          Date: {moment(transaction._id).format("DD-MM-YYYY")}
                        </p>
                      </div>
                      <div style={{ padding: "5px" }}>
                        <p
                          style={{
                            fontSize: "14px",
                            textAlign: "left",
                            margin: "3px 0",
                          }}
                        >
                          Rebate Total :{" "}
                          <span style={{ fontWeight: "400" }}>
                            ${transaction.rebateTotal.toLocaleString()}
                          </span>
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            textAlign: "left",
                            margin: "3px 0",
                          }}
                        >
                          Commission Total :{" "}
                          <span style={{ fontWeight: "400" }}>
                            ${transaction.commissionTotal.toLocaleString()}
                          </span>
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            textAlign: "left",
                            margin: "3px 0",
                          }}
                        >
                          No of Transactions :{" "}
                          <span style={{ fontWeight: "400" }}>
                            {transaction.txnCount.toLocaleString()}
                          </span>
                        </p>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#ffd700",
                          fontWeight: "bold",
                          fontSize: "14px",
                          cursor: "pointer",
                          padding: "5px 0",
                        }}
                        onClick={() =>
                          downloadReport(
                            transaction.txnArray,
                            transaction._id.split("T")[0]
                          )
                        }
                      >
                        <p style={{ margin: "0" }}>Download Report</p>
                      </div>
                    </Item>
                  </Grid>
                );
              })}
            {/* {transactions.length == 0 && <h4>No Data Available</h4>} */}
          </Grid>
        </Item>
      </Box>
    </>
  );
};
function CustomButton(props) {
  const { dataRangeby, customFunction, displayString } = props;
  return <Button
    variant="contained"
    style={{
      backgroundColor: dataRangeby == displayString ? "#ffd700" : "black",
      color: dataRangeby == displayString ? "black" : "white",
      height: "32px",
      marginRight: "10px",
    }}
    onClick={() => {
      customFunction()
    }}
  >
    {displayString}
  </Button>
}
export default TransactionExecutionReport;
