import { Bar } from '@ant-design/charts';
const CustomBarChart = (props) => {
    const { data } = props;
    function getFormattedStatValue(value) {
        let val = null;
        value = parseFloat(value)

        if (value < 1000) {
            val = value
        } else if (value >= 1000 && value < 1000000) {
            val = Math.round(value / 1000).toLocaleString() + "K";
        } else if (value >= 1000000) {
            val = Math.round(value / 1000000).toLocaleString() + "M";

        }
        return val;
    }
    const config = {
        title: {
            visible: true,
            text: 'Basic Bar Chart',
        },
        xAxis: {
            grid: { line: { style: 0 } },
            label: {
                formatter: (label) => {
                    return getFormattedStatValue(label);
                },
            },
        },
        description: {
            visible: true,
            text: 'Add conversion rate label graphics between the graphics of the basic bar chart\uFF0CUsers want to pay attention to the proportion of data changes from top to bottom',
        },
        // style: { fill: 'red' },
        // color: ['#19CDD7', '#DDB27C'],
        forceFit: true,
        label: {
            formatter: ({ value }) => {
                return getFormattedStatValue(value);
                // return value.toLocaleString();
            },
            position: "middle",
            style: { opacity: 1, fill: "black" },
        },
        data: data,
        xField: 'value',
        yField: 'symbolName',
        color: "#ffd700"
        // conversionTag: { visible: true },
    };
    return < Bar {...config} />;
}
export default CustomBarChart;