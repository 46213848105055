import { Line } from '@ant-design/charts';
const CustomLineChart = (props) => {
    const { data, selectedDisplayType } = props
    function getFormattedStatValue(value) {
        let val = null;
        value = parseFloat(value)

        if (value < 1000) {
            val = value
        } else if (value >= 1000 && value < 1000000) {
            val = Math.round(value / 1000).toLocaleString() + "K";
        } else if (value >= 1000000) {
            val = Math.round(value / 1000000).toLocaleString() + "M";

        }
        return val;
    }
    const config = {
        data: data,
        xField: 'item',
        yField: 'value',
        seriesField: 'type',
        yAxis: {
            label: {
                formatter: (v) => selectedDisplayType == "By Percentage" ? `${v}%` : getFormattedStatValue(v),
            },
            grid: { line: { style: 0 } },
        },
        // label: {
        //   type: 'inner',
        //   offset: '-30%',
        //   content: ({ percent }) => `${(percent)}%`,
        //   style: {
        //     fontSize: 14,
        //     textAlign: 'center',
        //   },
        // },
        tooltip: {
            formatter: (datum) => {
                return { name: datum.type, value: selectedDisplayType == "By Percentage" ? datum.value + '%' : datum.value };
            },
            style: {
                fill: 'red',
                fontSize: "500px"
            }
        },
        label: {
            formatter: ({ value }) => {
                if (!value)
                    return 0;
                return selectedDisplayType == "By Percentage" ? `${value}%` : getFormattedStatValue(value);
            },
            position: "top",
            style: { opacity: 1, fill: "black" },
            offset: 20,
        },
    };

    return <Line {...config} />;
};
export default CustomLineChart;