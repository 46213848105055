export const BASE_URL = "https://dashboard.century.ae/api";

// export const BASE_URL = "http://10.10.40.39:5000";

export const CLIENT_OPERATION_LOGIN = "/users/postClientOperationsLogin";

export const GET_ENCRYPTION_PAYLOAD = BASE_URL + "/users/getEncryptionPayload";

export const AUTHENTICATE_CLIENT_OPERATIONS =
  BASE_URL + "/users/authenticateClientOperations";

export const CHECK_ALLOWED_USERS = BASE_URL + "/TU/users/checkAllowedUsers";

export const GET_PUBLIC_KEY = BASE_URL + "/TU/users/getPublicKey";

export const LEAN_BASE_URL = BASE_URL + "/leanRoute";

export const GET_LEAN_PAYMENT_MASTER = LEAN_BASE_URL + "/getLeanPaymentMaster";

export const GET_CLIENT_MATCHED_MASTER =
  LEAN_BASE_URL + "/getClientMatchedMaster";

export const ACCOUNT_LOGIN_API = BASE_URL + "/accountRoute/loginRequest";

export const GET_TOP_CRs = "/users/getTopCRs";

export const GET_MONTHLY_TRANSACTION_FILTERED = "/users/getMonthlyTransactionFiltered";
