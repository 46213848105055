import { Bar } from "@ant-design/plots";

import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Popover,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AreaChartComponent from "../../components/charts/areachart";
import AreaChartComponentCFC from "../../components/charts/areachartCFC";
import BarLineChartComponent from "../../components/charts/barlinechart";
import HeatMapComponent from "../../components/charts/heatmap";
import TreeMapComponent from "../../components/charts/treeMapChart";

import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVLink } from "react-csv";
import {
  BASE_URL,
  GET_DISTINCT_CHART_DATA,
  GET_HOLDING_COST_TOP_CR,
  GET_MIS_DATA,
  GET_USER_TRANSACTIONS,
  GET_ENCRYPTED_EMAIL,
} from "../../helpers/adminApiStrings";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RMAppMetricsComponent from "../../components/RM-app-metrics-component";

//Date Ranger Pickers imports
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import moment from "moment";
import DateRangeTimePicker from "../../components/global/dateRangeTimePicker";
import DashboardTooltipItems from "../../helpers/dashboardTooltipItems";
import {
  GET_MONTHLY_TRANSACTION_FILTERED,
  GET_TOP_CRs,
} from "../../helpers/apiStrings";
import CustomMultiSelector from "../../components/customMultiSelector";
import CustomMultiSelector1 from "../../components/customMultiSelector1";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#04832a" : "#04832a",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function MgmtHome(props) {
  const navigate = useNavigate();
  const [txnData, setTxnData] = useState([]);
  const [brokerAccessURL, setBrokerAccessURL] = useState([]);
  const [brokerAccessMessage, setBrokerAccessMessage] = useState();
  const [heatMapData, setHeatMapData] = useState();
  const [monthlyDataSummary, setMonthlyDataSummary] = useState();
  const [heatMapHoldingCostData, setHeatMapHoldingCostData] = useState();
  const [treeMapSymbolArrayHoldingCost, setTreeMapSymbolArrayHoldingCost] =
    useState();
  const [statistics, setStatistics] = useState();
  const [sortedUserListByVol, setSortedUserListByVol] = useState();
  const [sortedUserListByTxn, setSortedUserListByTxn] = useState();
  const [sortedUserListByCommission, setSortedUserListByCommission] =
    useState();
  const [sortedUserListBySpread, setSortedUserListBySpread] = useState();
  //TOP CR list
  const [topCRListVolMap, setTopCRListVolMap] = useState();
  const [topCRListComMap, setTopCRListComMap] = useState();
  const [topCRListCountMap, setTopCRListCountMap] = useState();
  const [topCRListSpreadMap, setTopCRListSpreadMap] = useState();
  const [topCRListHoldingCostMap, setTopCRListHoldingCostMap] = useState();
  const [value, setValue] = React.useState(0);
  const [valueTopCR, setValueTopCR] = React.useState(0);
  const [monthWiseTxnTotal, setMonthWiseTxnTotal] = useState();
  const [monthWiseCommissionSpread, setMonthWiseCommissionSpread] = useState();
  const [monthWiseActiveClients, setMonthWiseActiveClients] = useState([]);
  const [totAndActClients, setTotAndActiveClients] = useState([]);
  const [symbolArray, setSymbolArray] = useState([]);
  const [treeMapSymbolArray, setTreeMapSymbolArray] = useState();
  const [user, setUser] = useState();
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [cachedData, setCachedData] = useState({});
  const filterValues = useRef({
    client: [],
    symbol: "",
    startDate: "",
    endDate: "",
    user: "All",
  });
  const [clientList, setClientList] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [tempListSymbol, setTempListSymbol] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [emailAccountsList, setEmailAccountsList] = useState({});
  const [emailList, setEmaiLList] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [holdingCostValue, setHoldingCostValue] = useState(0);
  const [holdingCostByClient, setHoldingCostByClient] = useState();
  const [activeClientList, setActiveClientList] = useState();
  const [showLoader, setShowLoader] = useState("block");
  const [isChartDataGridOpen, setIsChartDataGridOpen] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [gridDataTitle, setGridDataTitle] = useState("");
  const [dataErrorMsg, setDataErrorMsg] = useState(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState("");
  const [firstUpdatedDate, setFirstUpdatedDate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [fundingTxns, setFundingTxns] = useState([]);
  const uproDataEncrypted = searchParams.get("d") || "";
  const [fundingStatistics, setFundingStatistics] = useState({});
  const [maskingState, setMaskingState] = useState(true);
  const [showAppMetrics, setShowAppMetrics] = useState(false);
  const [showBrokerTerminal, setShowBrokerTerminal] = useState(false);
  const [
    showBrokerTerminalPermissionDialog,
    setShowBrokerTerminalPermissionDialog,
  ] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [topCRsLoading, setTopCRsLoading] = useState(false);
  const [treeMapLoading, setTreeMapLoading] = useState(false);
  const [heatMapLoading, setHeatMapLoading] = useState(false);
  const [monthlyDataLoding, setMonthlyDataLoding] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openDropDownSymbol, setOpenDropDownSymbol] = useState(false);
  const [monthlyTransactionFilterValue, setMonthlyTransactionFilterValue] =
    useState();
  const [clientBehaviourColorField, setClientBehaviourColorField] =
    useState("count");
  const [dateRangeString, setDateRangeString] = useState(
    "--/--/----  -  --/--/----"
  );
  const [platformType, setPlatformType] = useState("CFC");
  const [checked, setsetChecked] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);
  const [selectedArraySymbol, setSelectedArraySymbol] = useState([]);
  const DashboardItemGridSize = { lg: 2, xl: 2, md: 4, sm: 6, xs: 12 };

  const handleChartDataGridClose = () => {
    setIsChartDataGridOpen(false);
    setGridData([]);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 180,
      },
    },
  };

  useEffect(() => {
    document.title = "Rebate Dashboard";
    if (searchParams.get("d")) {
      getTxns();
      // getFundingTxns();
    } else window.location = "https://upro.century.ae";
  }, [platformType]);

  const setStateValues = (data, holdingCostData) => {
    if (holdingCostData == null) {
      setHoldingCostValue(0);
    }
    if (data == null) {
      setTxnData([]);
      setStatistics({
        txnVolume: 0,
        txnCount: 0,
        activeUserCount: 0,
        commissionTotal: 0,
        spreadTotal: 0,
      });
      setMonthWiseCommissionSpread([]);
      setHeatMapData([]);
      setTotAndActiveClients([]);
      setMonthWiseTxnTotal([]);
      setSortedUserListByTxn([]);
      setSortedUserListByVol([]);
      setSortedUserListByCommission([]);
      setSortedUserListBySpread([]);
      setTreeMapSymbolArray([]);
      setLastUpdatedDate("");
      setFirstUpdatedDate("");
      setFundingTxns([]);
      setFundingStatistics({});
      return setDataErrorMsg("Please reached out to Century IT team");
    }
    //	data.txnData["Spread"] = Math.round(float(data.txnData["Spread"])*100)/100
    setTxnData(data.txnData);
    if (Object.keys(data.totalStats).length) setStatistics(data.totalStats);
    else setStatistics();

    setMaskingState(true);
    setHoldingCostValue(holdingCostData.totalSwap);
    setHeatMapHoldingCostData(holdingCostData.heatMapHoldingCostData);
    setTreeMapSymbolArrayHoldingCost(
      holdingCostData.treeMapSymbolArrayHoldingCost
    );
    try {
      let a = [];
      if (Object.keys(holdingCostData).length) {
        a = [
          ...data.monthWiseCommissionSpread,
          ...holdingCostData.monthlyHoldingCost,
        ];
        setHoldingCostByClient(holdingCostData.topClientsByHoldingCost);
      } else {
        a = [...data.monthWiseCommissionSpread];
        setHoldingCostByClient();
      }
      setMonthWiseCommissionSpread(a);
    } catch (e) {
      console.log(e.message);
    }
    setMonthWiseTxnTotal(data.monthWiseTxnTotal);

    if (data.lastUpdatedDate != undefined) {
      setLastUpdatedDate(data.lastUpdatedDate);
      setFirstUpdatedDate(data.firstUpdatedDate);
    }
    if (Object.keys(data).length) {
      setFundingTxns(data.funding[0].txnDump);

      let statisticsOfFunding = {};
      if (data.funding[0].fundingStatistics.length > 0) {
        statisticsOfFunding = {
          deposits: data.funding[0].fundingStatistics[0].deposits,
          withdrawals: data.funding[0].fundingStatistics[0].withdrawals,
          creditIns: data.funding[0].fundingStatistics[0].creditIns,
          creditOuts: data.funding[0].fundingStatistics[0].creditOuts,
        };
      }
      setFundingStatistics(statisticsOfFunding);
    }
    const TACC = data.monthWiseActiveClients.map((m) => {
      return {
        type: "Passive",
        date: m.date,
        count: data.clients.length - m.count,
      };
    });
    Array.prototype.push.apply(TACC, data.monthWiseActiveClients);
    setTotAndActiveClients(TACC);

    setSortedUserListByTxn(data.sortedUserListByTxn);
    setSortedUserListByVol(data.sortedUserListByVol);
    setSortedUserListByCommission(data.sortedUserListByCommission);
    setSortedUserListBySpread(data.sortedUserListBySpread);
    if (data.symbolArray) {
      setSymbolArray(data.symbolArray);
    }
    setTreeMapSymbolArray(data.treeMapSymbolArray);
    setHeatMapData(data.heatMapData);
    setClientList(data.clients);
    setTempList(["All"].concat(data.clients));
    setTempListSymbol(["All"].concat(data.symbolArray));
    setEmailAccountsList(data.emailAccounts);
    setEmaiLList(data.emailList);
  };
  let paramString = platformType == "TU" ? "TU" : "CFC";
  function getDistinctChartData(chartName, filterType) {
    if (chartName === "heatMap") setHeatMapLoading(true);
    else if (chartName === "treeMap") {
      setTreeMapLoading(true);
    }

    axios
      .post(BASE_URL + "/" + platformType + GET_DISTINCT_CHART_DATA, {
        values: filterValues.current,
        uproData: uproDataEncrypted,
        chartName,
        filterType,
      })
      .then((res) => {
        if (chartName === "heatMap") {
          setHeatMapLoading(false);
          if (filterType === "byTxnCount") {
            setClientBehaviourColorField("count");
          } else if (filterType === "byTxnVolume") {
            setClientBehaviourColorField("Volume");
          } else if (filterType === "byTxnCommission") {
            setClientBehaviourColorField("commission");
          } else if (filterType === "byTxnSpread") {
            setClientBehaviourColorField("spread");
          } else {
            setClientBehaviourColorField("Users");
          }

          setHeatMapData(res.data);
        } else if (chartName === "treeMap") {
          setTreeMapLoading(false);
          setTreeMapSymbolArray(res.data);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }
  function getMonthlyTransactionSummaryData(filterType) {
    setMonthlyDataLoding(true);

    axios
      .post(BASE_URL + "/" + platformType + GET_MONTHLY_TRANSACTION_FILTERED, {
        values: filterValues.current,
        uproData: uproDataEncrypted,
        filterType,
      })
      .then((res) => {
        setMonthlyDataLoding(false);
        setMonthWiseTxnTotal(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }
  const getTxns = () => {
    document.getElementById("heatMap").value = "byTxnCount";
    document.getElementById("treeMap").value = "byTxnCount";
    filterValues.current.client = selectedArray;
    filterValues.current.symbol = selectedArraySymbol;
    if (document.getElementById("dropdownviewSymbol").innerHTML == "All") {
      filterValues.current.symbol = ["All"];
      setTempListSymbol(["All"].concat(symbolArray));
      // setSelectedArraySymbol(["All"].concat(symbolArray))
    }
    if (document.getElementById("dropdownview").innerHTML == "All") {
      filterValues.current.client = ["All"];
      setTempList(["All"].concat(clientList));
      // setSelectedArray(["All"].concat(clientList))
    }
    axios
      .get("https://api.ipify.org/?format=json")
      .then(function (res) {
        if (res.data.ip != "91.74.132.214" && res.data.ip != "5.195.213.42") {
          setBrokerAccessMessage(
            "Broker access can only be used in office network"
          );
        } else {
          setBrokerAccessMessage();
        }
      })
      .catch((e) => {
        console.log(e);
      });

    setShowLoader("block");
    if (
      filterValues.current.startDate != "" &&
      filterValues.current.endDate != "" &&
      filterValues.current.startDate >= filterValues.current.endDate
    ) {
      return setDataErrorMsg("Start Date should be less than end date");
    }
    const cacheKey = JSON.stringify(filterValues.current);
    try {
      if (cachedData.hasOwnProperty(cacheKey)) {
        setStateValues(cachedData[cacheKey]);
      }
    } catch (e) {
      console.log("cached Data catch");
    }
    axios
      .post(BASE_URL + "/" + paramString + GET_USER_TRANSACTIONS, {
        values: filterValues.current,
        uproData: uproDataEncrypted,
      })
      .then((res) => {
        // if (res.data.txnData.length == 0) { console.log("testing in set no dat"); setNoDataFound(true) };
        setCachedData((oldState) => {
          return { ...oldState, [cacheKey]: res.data };
        });
        var temp = {};
        temp["name"] = res.data.userName;
        temp["email"] = res.data.userEmail;
        if (!user) setUser(temp);
        // console.log(res.data);
        setBrokerAccessURL(
          "https://centra.century.ae?em=" + res.data.userEmailEncrypted
        );
        //this is for MIS API

        axios
          .post(BASE_URL + "/" + paramString + GET_MIS_DATA, {
            platform: paramString,
            values: filterValues.current,
            clientList: res.data.clients,
          })
          .then((resp) => {
            if (res.data.txnData.length == 0 && res.data.length == 0) {
              setNoDataFound(true);
            } else {
              setStateValues(res.data, resp.data);
              setShowLoader("none");
            }
          })
          .catch((e) => {
            setNoDataFound(true);
            setShowLoader("none");
          });
        //this is TOP CRs Api
        setTopCRsLoading(true);
        axios
          .post(BASE_URL + "/" + paramString + GET_TOP_CRs, {
            values: filterValues.current,
            uproData: uproDataEncrypted,
          })
          .then((resp) => {
            setTopCRListComMap(resp.data.topCRListComMap);
            setTopCRListVolMap(resp.data.topCRListVolMap);
            setTopCRListCountMap(resp.data.topCRListCountMap);
            setTopCRListSpreadMap(resp.data.topCRListSpreadMap);
            axios
              .post(BASE_URL + "/" + paramString + GET_HOLDING_COST_TOP_CR, {
                uproData: uproDataEncrypted,
                platform: paramString,
                values: filterValues.current,
                clientList: res.data.clients,
              })
              .then((resp) => {
                setTopCRListHoldingCostMap(resp.data.topCRListHoldingCostMap);
                setTopCRsLoading(false);
              })
              .catch((e) => {
                console.log("ERROR: ", e.message);
              });
          })
          .catch((e) => {
            console.log("ERROR: ", e.message);
          });
      })
      .catch((err) => {
        setShowLoader("none");
        if (err.response.status === 503 || err.response.status === 404) {
          setCachedData((oldState) => {
            return { ...oldState, [cacheKey]: null };
          });

          setStateValues(cachedData[cacheKey]);
        }
      });
  };
  const togglePlatformType = () => {
    setDateRange(null);
    filterValues.current.startDate = "";
    filterValues.current.endDate = "";
    filterValues.current.client = [];
    filterValues.current.symbol = "All";
    filterValues.current.user = "All";
    if (platformType == "TU") {
      setCachedData({});
      setPlatformType("CFC");
    } else {
      setCachedData({});
      setPlatformType("TU");
    }
  };
  function handleValueChange(e) {
    let old = filterValues.current;
    old[e.target.name] = e.target.value;
    if (e.target.name === "client") {
      old[e.target.name] = selectedArray;
    }
    filterValues.current = old;
    if (e.target.name === "user" && e.target.value !== "All") {
      setClientList(emailAccountsList[e.target.value].accountList);
      setUserEmail(e.target.value);
    } else if (e.target.name === "symbol") {
      old[e.target.name] = selectedArraySymbol;
      setSelectedSymbol(e.target.value);
    } else if (e.target.name === "startDate") {
      setSelectedStartDate(e.target.value);
    } else if (e.target.name === "endDate") {
      setSelectedEndDate(e.target.value);
    }
  }

  const ToggleAppMetrics = (bool) => {
    setShowAppMetrics(bool);
  };
  const ToggleBrokerTerminal = (bool) => {
    setShowBrokerTerminal(bool);
  };
  function resetSelect(param, temp) {
    var mySelect = document.getElementById(param);
    for (var i, j = 0; (i = mySelect.options[j]); j++) {
      if (i.value == temp) {
        mySelect.selectedIndex = j;
        break;
      }
    }
  }
  function resetFilter() {
    document.getElementById("dropdownview").innerHTML = "All";
    document.getElementById("dropdownviewSymbol").innerHTML = "All";
    filterValues.current.user = "All";
    filterValues.current = {
      client: clientList,
      symbol: symbolArray,
      startDate: "",
      endDate: "",
      user: "All",
    };
    setSelectedArray(["All"].concat(clientList));
    setSelectedArraySymbol(["All"].concat(symbolArray));
    setUserEmail("");
    setSelectedSymbol("");
    setSelectedStartDate("");
    setSelectedEndDate("");
    setDateRange(null);
    setDateRangeString("--/--/----  -  --/--/----");
    getTxns();
    resetSelect("treeMap", "byTxnCount");
    resetSelect("heatMap", "byTxnCount");
  }

  const logout = () => {
    window.location = "https://upro.century.ae";
  };

  return (
    <>
      {noDataFound ? (
        <NoDataElement />
      ) : (
        <div
          className="container"
          onClick={(e) => {
            const list = document.getElementById("Dropdown");
            try {
              let arr = ["All", ...clientList];
              if (e.target.id != "Dropdown" && e.target.id != "myInput") {
                let target = e.target.id;

                if (e.target.tagName == "DIV" || e.target.tagName == "LABEL") {
                  target = target.substr(0, target.length - 1);
                  if (target != "All") {
                    target = Number(target);
                  }
                  if (!arr.includes(target)) {
                    list.style.display = "none";
                    setOpenDropDown(false);
                    setTempList(["All"].concat(clientList));
                  }
                } else {
                  if (target != "All") {
                    target = Number(target);
                  }
                  if (!arr.includes(target)) {
                    list.style.display = "none";
                    setOpenDropDown(false);
                    setTempList(["All"].concat(clientList));
                  }
                }
              }
            } catch (e) {}
            const listSymbol = document.getElementById("DropdownSymbol");
            let arrSymbol = ["All", ...symbolArray];
            if (
              e.target.id != "DropdownSymbol" &&
              e.target.id != "myInputSymbol"
            ) {
              let target = e.target.id;

              if (e.target.tagName == "DIV" || e.target.tagName == "LABEL") {
                target = target.substr(0, target.length - 1);
                if (!arrSymbol.includes(target)) {
                  listSymbol.style.display = "none";
                  setOpenDropDownSymbol(false);
                  setTempListSymbol(["All"].concat(symbolArray));
                }
              } else {
                if (!arrSymbol.includes(target)) {
                  listSymbol.style.display = "none";
                  setOpenDropDownSymbol(false);
                  setTempListSymbol(["All"].concat(symbolArray));
                }
              }
            }
          }}
        >
          <div
            style={{
              height: "120vh",
              width: "100vw",
              position: "fixed",
              zIndex: "5",
              backgroundColor: "#fffffffa",
              backdropFilter: "blur(5px)",
              display: showLoader,
            }}
          >
            <h1>LOADING...</h1>
          </div>

          <Dialog open={dataErrorMsg}>
            <DialogContent>{dataErrorMsg}</DialogContent>
            <DialogActions>
              <Button onClick={() => setDataErrorMsg(false)}>Close</Button>
            </DialogActions>
          </Dialog>
          {/*This is Dialog for Broker Access */}
          <Dialog open={showBrokerTerminalPermissionDialog}>
            <DialogContent>{brokerAccessMessage}</DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowBrokerTerminalPermissionDialog(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={false}>
            <DialogContent>No Data Found</DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setNoDataFound(false);
                  resetFilter();
                  window.location.reload();
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={showAppMetrics} fullWidth={true} maxWidth={"xxl"}>
            <RMAppMetricsComponent
              ToggleAppMetrics={ToggleAppMetrics}
              platformType={platformType}
            />
          </Dialog>
          {txnData.length > 0 && (
            <Dialog open={showBrokerTerminal} fullWidth={true} maxWidth={"xxl"}>
              {/* {!brokerAccessMessage && ( */}
              {/* {alert(brokerAccessURL)} */}
              <div>
                <CancelPresentationIcon
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginRight: "20px",
                  }}
                  onClick={() => {
                    setShowBrokerTerminal(false);
                  }}
                />
                <iframe
                  src={brokerAccessURL}
                  width="100%"
                  height="700"
                  style={{ overflowX: "hidden", border: "none" }}
                  // onload={handleIframeLoad}
                ></iframe>
              </div>
              {/* )}
            {brokerAccessMessage && (
              <div style={{ padding: "10%" }}>
                <h4>{brokerAccessMessage}</h4>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "gold" }}
                  onClick={() => {
                    setShowBrokerTerminal(false);
                  }}
                >
                  Close
                </Button>
              </div>
            )} */}
            </Dialog>
          )}
          <Dialog
            fullWidth
            open={isChartDataGridOpen}
            onClose={handleChartDataGridClose}
          >
            <DialogTitle>{gridDataTitle}</DialogTitle>
            <DialogContent>
              {gridData.length === 0 && <h3>Loading data...</h3>}
              {gridData.length > 0 && (
                <Box sx={{ height: 400, width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.loginId}
                    rows={gridData}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    columns={[
                      {
                        field: "loginId",
                        flex: 1,
                      },
                      {
                        field: "status",
                        flex: 1,
                        // renderCell: (param) => {
                        //   return param.row.status;
                        // },
                      },
                    ]}
                    experimentalFeatures={{ newEditingApi: true }}
                  />
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleChartDataGridClose}>Close</Button>
            </DialogActions>
          </Dialog>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    m="auto"
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        mb: 1,
                        width: "90%",
                        minHeight: "50px",
                        padding: "0 5%",
                      },
                    }}
                  >
                    <Paper sx={{ height: "auto", backgroundColor: "gold" }}>
                      <Grid container spacing={2}>
                        <Grid item lg={6} xl={6} md={12} sm={12} xs={12}>
                          <h1 align="left">
                            {platformType == "TU" ? "Tradeultra " : "Century "}{" "}
                            Dashboard{" "}
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "black" }}
                              onClick={() => {
                                setShowAppMetrics(true);
                              }}
                            >
                              View App Metrics
                            </Button>
                            {platformType == "CFC" && (
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "black",
                                  marginLeft: "10px",
                                }}
                                onClick={() => {
                                  // if (showBrokerTerminalPermission) {
                                  if (brokerAccessMessage == undefined) {
                                    setShowBrokerTerminal(true);
                                  } else {
                                    setShowBrokerTerminalPermissionDialog(true);
                                  }
                                }}
                              >
                                Broker Access
                              </Button>
                            )}
                          </h1>
                        </Grid>

                        <Grid item lg={6} xl={6} md={12} sm={12} xs={12}>
                          <div
                            align="right"
                            style={{
                              float: "right",
                              height: "90px",
                              lineHeight: "90px",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <h4
                              style={{
                                margin: "0",
                                marginRight: "10px",
                                float: "left",
                              }}
                            >
                              Welcome {user && user.name}
                            </h4>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "black",
                                height: "32px",
                                margin: "auto",
                              }}
                              onClick={() => {
                                logout();
                              }}
                            >
                              Logout
                            </Button>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              pl={"15px"}
                              sx={{ display: "flex" }}
                            >
                              <Typography style={{ fontWeight: 700 }}>
                                CENTURY
                              </Typography>
                              <AntSwitch
                                checked={platformType == "TU" ? true : false}
                                onChange={(checked) => {
                                  togglePlatformType();
                                }}
                                inputProps={{ "aria-label": "ant design" }}
                              />
                              <Typography style={{ fontWeight: 700 }}>
                                TU
                              </Typography>
                            </Stack>
                          </div>
                        </Grid>
                      </Grid>
                    </Paper>

                    <Paper
                      sx={{
                        padding: "0 5%",
                        display: "flex",
                        height: "auto",
                        width: "90%",
                        backgroundColor: "#fafafa",
                        alignItems: "center",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item lg={5} xl={5} md={12} sm={12} xs={12}>
                          <Box
                            pt={2}
                            pb={2}
                            sx={{
                              boxSizing: "border-box",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "16px",
                                marginTop: "0",
                                textAlign: "left",
                              }}
                            >
                              <b>Please note:</b> We are constantly making
                              updates to the dashboard hence the data
                              represented may have minor differences.
                            </span>
                          </Box>
                        </Grid>
                        <Grid item lg={7} xl={7} md={12} sm={12} xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              width: "100%",
                            }}
                          >
                            <Box
                              pt={2}
                              pb={2}
                              sx={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  lg={0}
                                  xl={0}
                                >
                                  <Box
                                    sx={{
                                      display: {
                                        lg: "none",
                                        xl: "none",
                                        md: "flex",
                                        sm: "flex",
                                        xs: "flex",
                                      },
                                      alignItems: "center",
                                      gap: 2,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        height: "1px",
                                        backgroundColor: "divider",
                                        flex: 1,
                                      }}
                                    ></Box>
                                    <Box>Filters</Box>
                                    <Box
                                      sx={{
                                        height: "1px",
                                        backgroundColor: "divider",
                                        flex: 1,
                                      }}
                                    ></Box>
                                  </Box>
                                </Grid>
                                <Grid item lg={2} xl={2} md={2} sm={6} xs={12}>
                                  <FormControl sx={{ width: "100%" }}>
                                    <Autocomplete
                                      disablePortal
                                      size="small"
                                      id={"teamMember"}
                                      defaultValue={"All"}
                                      value={filterValues.current.user}
                                      onChange={(e, option) => {
                                        handleValueChange({
                                          target: {
                                            name: "user",
                                            value: option,
                                          },
                                        });
                                      }}
                                      options={["All"].concat(emailList)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Team Member"
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                                {/* Optimization will be done to make single file for multi-selector */}
                                <Grid item lg={2} xl={2} md={2} sm={6} xs={12}>
                                  <CustomMultiSelector
                                    openDropDown={openDropDown}
                                    setOpenDropDown={setOpenDropDown}
                                    setSelectedArray={setSelectedArray}
                                    selectedArray={selectedArray}
                                    data={clientList}
                                    tempList={tempList}
                                    setTempList={setTempList}
                                  />
                                </Grid>
                                <Grid item lg={2} xl={2} md={2} sm={6} xs={12}>
                                  <CustomMultiSelector1
                                    openDropDownSymbol={openDropDownSymbol}
                                    setOpenDropDownSymbol={
                                      setOpenDropDownSymbol
                                    }
                                    setSelectedArraySymbol={
                                      setSelectedArraySymbol
                                    }
                                    selectedArraySymbol={selectedArraySymbol}
                                    data={symbolArray}
                                    tempListSymbol={tempListSymbol}
                                    setTempListSymbol={setTempListSymbol}
                                  />
                                </Grid>

                                <Grid item lg={4} xl={4} md={6} sm={12} xs={12}>
                                  <DateRangeTimePicker
                                    state={dateRange}
                                    setState={setDateRange}
                                    filterValues={filterValues}
                                  />
                                </Grid>

                                <Grid item lg={1} xl={1} md={3} sm={6} xs={12}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    style={{ backgroundColor: "black" }}
                                    onClick={getTxns}
                                  >
                                    Submit
                                  </Button>
                                </Grid>
                                <Grid item lg={1} xl={1} md={2} sm={6} xs={12}>
                                  <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={resetFilter}
                                  >
                                    Clear
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>

              <p>
                {
                  <span style={{ background: "gold", padding: "0.5% 1%" }}>
                    <b>
                      Dashboard has been updated with trading data from{" "}
                      {firstUpdatedDate.split("T")[0]} to{" "}
                      {lastUpdatedDate.split("T")[0]}
                    </b>
                  </span>
                }{" "}
                <span
                  style={{
                    float: "right",
                    marginRight: "16px",
                    background: "gold",
                    padding: "0.5% 1%",
                  }}
                >
                  <b>Timezone: GMT +3</b>
                </span>
              </p>
              <Grid container spacing={2}>
                <Grid item {...DashboardItemGridSize}>
                  <Box
                    m="auto"
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "100%",
                        height: 128,
                      },
                    }}
                  >
                    <Paper style={{ backgroundColor: "#111" }}>
                      {" "}
                      <p
                        style={{
                          color: "gold",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Total Transactions{" "}
                        <InfoPopover
                          color="gold"
                          content={DashboardTooltipItems["totalTxns"]}
                        />
                      </p>
                      <h2 style={{ color: "white" }}>
                        {statistics && !noDataFound
                          ? statistics.txnCount.toLocaleString()
                          : 0}
                      </h2>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item {...DashboardItemGridSize}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "100%",
                        height: 128,
                      },
                    }}
                  >
                    <Paper style={{ backgroundColor: "#111", color: "gold" }}>
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Total Active Clients{" "}
                        <InfoPopover
                          color="gold"
                          content={DashboardTooltipItems["totalActiveClients"]}
                        />
                      </p>
                      <h2 style={{ color: "white" }}>
                        {statistics && !noDataFound
                          ? statistics.activeUserCount
                          : 0}
                      </h2>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item {...DashboardItemGridSize}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "100%",
                        height: 128,
                      },
                    }}
                  >
                    <Paper style={{ backgroundColor: "#111", color: "gold" }}>
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Total Txn Volume{" "}
                        <InfoPopover
                          color="gold"
                          content={DashboardTooltipItems["totalTxnVolume"]}
                        />
                      </p>
                      <h2 style={{ color: "white" }}>
                        USD{" "}
                        {statistics && !noDataFound
                          ? (
                              Math.round(
                                (statistics.txnVolume / 1000000) * 1000
                              ) / 1000
                            ).toLocaleString()
                          : 0}
                        M
                      </h2>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item {...DashboardItemGridSize}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "100%",
                        height: 128,
                      },
                    }}
                  >
                    <Paper style={{ backgroundColor: "#111", color: "gold" }}>
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Total Commissions
                        <InfoPopover
                          color="gold"
                          content={DashboardTooltipItems["totalCommissions"]}
                        />
                        <div style={{ marginLeft: "10px", cursor: "pointer" }}>
                          {maskingState ? (
                            <VisibilityOffIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          )}
                        </div>
                      </p>
                      <h2 style={{ color: "white" }}>
                        USD{" "}
                        {maskingState
                          ? statistics && !noDataFound
                            ? ("" + statistics.commissionTotal).charAt(0) +
                              "xxxxx"
                            : 0
                          : statistics && !noDataFound
                          ? Math.round(
                              statistics.commissionTotal
                            ).toLocaleString()
                          : 0}
                      </h2>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item {...DashboardItemGridSize}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "100%",
                        height: 128,
                      },
                    }}
                  >
                    <Paper style={{ backgroundColor: "#111", color: "gold" }}>
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Total Spread{" "}
                        <InfoPopover
                          color="gold"
                          content={DashboardTooltipItems["totalSpread"]}
                        />
                        <div style={{ marginLeft: "10px", cursor: "pointer" }}>
                          {maskingState ? (
                            <VisibilityOffIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          )}
                        </div>
                      </p>
                      <h2 style={{ color: "white" }}>
                        USD{" "}
                        {maskingState
                          ? statistics && !noDataFound
                            ? ("" + statistics.spreadTotal).charAt(0) + "xxxxx"
                            : 0
                          : statistics && !noDataFound
                          ? Math.round(statistics.spreadTotal).toLocaleString()
                          : 0}
                      </h2>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item {...DashboardItemGridSize}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "100%",
                        height: 128,
                      },
                    }}
                  >
                    <Paper style={{ backgroundColor: "#111", color: "gold" }}>
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Total Holding Cost{" "}
                        <InfoPopover
                          color="gold"
                          content={DashboardTooltipItems["totalHoldingCost"]}
                        />
                        <div style={{ marginLeft: "10px", cursor: "pointer" }}>
                          {maskingState ? (
                            <VisibilityOffIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          )}
                        </div>
                      </p>
                      <h2 style={{ color: "white" }}>
                        USD{" "}
                        {maskingState
                          ? holdingCostValue &&
                            ("" + holdingCostValue).charAt(0) + "xxxxx"
                          : holdingCostValue &&
                            Math.round(holdingCostValue).toLocaleString()}
                      </h2>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
              <br></br>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={3}>
                  <Box
                    m="auto"
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "90%",
                        minHeight: "400px",
                        padding: "0 5%",
                      },
                    }}
                  >
                    <Paper sx={{ height: "300px" }}>
                      <span style={{ float: "right", marginTop: "10px" }}>
                        <CSVLink
                          filename="Monthly Transaction Summary"
                          data={monthWiseTxnTotal || []}
                        >
                          <SystemUpdateAltIcon sx={{ color: "black" }} />
                        </CSVLink>
                      </span>
                      <h2>
                        Transaction Summary{" "}
                        <InfoPopover
                          content={DashboardTooltipItems["monthlyTxnSummary"]}
                        />
                      </h2>
                      <Box sx={{ marginBottom: "10px" }}>
                        <label>Filter by </label>
                        <select
                          id={"getMonthlyTransactionSummaryData"}
                          defaultValue={"monthlyTransaction"}
                          style={{ fontSize: 14 }}
                          onChange={(e) => {
                            getMonthlyTransactionSummaryData(e.target.value);
                            setMonthlyTransactionFilterValue(e.target.value);
                          }}
                        >
                          <option value={"monthlyTransaction"}>Monthly</option>
                          <option value={"dailyTransaction"}>Daily</option>
                          <option value={"weeklyTransaction"}>Weekly</option>
                        </select>
                      </Box>
                      {monthWiseTxnTotal && monthWiseTxnTotal.length > 0 ? (
                        <BarLineChartComponent
                          data={monthWiseTxnTotal}
                          xField={"time"}
                          yFieldLine={"txnCount"}
                          yFieldBar={"txnVolume"}
                          monthlyTransactionFilterValue={
                            monthlyTransactionFilterValue
                          }
                        />
                      ) : (
                        <NoDataElement />
                      )}
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "90%",
                        minHeight: "400px",
                        padding: "0 5%",
                      },
                    }}
                  >
                    <Paper sx={{ height: "300px", position: "relative" }}>
                      <span style={{ float: "right", marginTop: "10px" }}>
                        <CSVLink
                          filename="Monthly Commissions, Spreads & Holding Cost"
                          data={monthWiseCommissionSpread || []}
                        >
                          <SystemUpdateAltIcon sx={{ color: "black" }} />
                        </CSVLink>
                      </span>
                      <h3
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <InfoPopover
                          content={DashboardTooltipItems["monthlyRevenue"]}
                        />{" "}
                        Commissions, Spreads & Holding Cost Monthly{" "}
                        <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                          {maskingState ? (
                            <VisibilityOffIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          )}
                        </span>
                      </h3>
                      {!noDataFound &&
                      monthWiseCommissionSpread &&
                      monthWiseCommissionSpread.length > 0 ? (
                        maskingState ? (
                          <h3
                            style={{
                              color: "goldenrod",
                              height: "250px !important",
                            }}
                          >
                            Data is Masked
                          </h3>
                        ) : (
                          <BarChartCompnent
                            sx={{ height: "250px !important" }}
                            chartData={monthWiseCommissionSpread}
                          />
                        )
                      ) : (
                        <NoDataElement />
                      )}
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "100%",
                        minHeight: "400px",
                      },
                    }}
                  >
                    <Paper sx={{ height: "300px" }}>
                      {!topCRsLoading && (
                        <>
                          {valueTopCR == 0 && (
                            <span
                              style={{
                                float: "right",
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <CSVLink
                                filename="Top CRs - Transaction Volume"
                                data={topCRListVolMap || []}
                              >
                                <SystemUpdateAltIcon sx={{ color: "black" }} />
                              </CSVLink>
                            </span>
                          )}
                          {valueTopCR == 1 && (
                            <span
                              style={{
                                float: "right",
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <CSVLink
                                filename="Top CRs - Transaction Count"
                                data={topCRListCountMap || []}
                              >
                                <SystemUpdateAltIcon sx={{ color: "black" }} />
                              </CSVLink>
                            </span>
                          )}
                          {valueTopCR == 2 && (
                            <span
                              style={{
                                float: "right",
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <CSVLink
                                filename="Top CRs - Commissions"
                                data={topCRListComMap || []}
                              >
                                <SystemUpdateAltIcon sx={{ color: "black" }} />
                              </CSVLink>
                            </span>
                          )}
                          {valueTopCR == 3 && (
                            <span
                              style={{
                                float: "right",
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <CSVLink
                                filename="Top CRs - Spread"
                                data={topCRListSpreadMap || []}
                              >
                                <SystemUpdateAltIcon sx={{ color: "black" }} />
                              </CSVLink>
                            </span>
                          )}
                          {valueTopCR == 4 && (
                            <span
                              style={{
                                float: "right",
                                marginTop: "10px",
                                marginRight: "10px",
                              }}
                            >
                              <CSVLink
                                filename="Top CRs - Holdning Cost"
                                data={topCRListHoldingCostMap || []}
                              >
                                <SystemUpdateAltIcon sx={{ color: "black" }} />
                              </CSVLink>
                            </span>
                          )}
                        </>
                      )}
                      <h2>
                        Top Relation Managers{" "}
                        <InfoPopover
                          content={DashboardTooltipItems["topCRs"]}
                        />
                        <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                          {maskingState ? (
                            <VisibilityOffIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          )}
                        </span>
                      </h2>
                      {!topCRsLoading ? (
                        maskingState ? (
                          <h3
                            style={{
                              color: "goldenrod",
                              height: "250px !important",
                            }}
                          >
                            Data is Masked
                          </h3>
                        ) : (
                          <>
                            <Tabs
                              value={valueTopCR}
                              onChange={(e, valueTopCR) => {
                                setValueTopCR(valueTopCR);
                              }}
                            >
                              <Tab
                                style={{ width: "20%", fontSize: "12px" }}
                                label="Volume"
                              />
                              <Tab
                                style={{ width: "20%", fontSize: "12px" }}
                                label="Txn Count"
                              />
                              <Tab
                                style={{ width: "20%", fontSize: "12px" }}
                                label="Commission"
                              />
                              <Tab
                                style={{ width: "20%", fontSize: "12px" }}
                                label="Spread"
                              />
                              <Tab
                                style={{ width: "20%", fontSize: "12px" }}
                                label="Holding"
                              />
                            </Tabs>
                            {valueTopCR === 0 && (
                              <div
                                style={{
                                  overflow: "auto",
                                  height: "250px",
                                }}
                              >
                                <Table
                                  stickyHeader
                                  style={{
                                    tableLayout: "fixed",
                                  }}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>Client</StyledTableCell>
                                      <StyledTableCell>
                                        Txn Volume
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {topCRListVolMap &&
                                      Object.keys(topCRListVolMap).map((x) => {
                                        return (
                                          <StyledTableRow>
                                            <StyledTableCell>
                                              {topCRListVolMap[x].email}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              USD{" "}
                                              {(
                                                Math.round(
                                                  topCRListVolMap[x].volume *
                                                    100
                                                ) / 100
                                              ).toLocaleString()}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </div>
                            )}
                            {valueTopCR === 1 && (
                              <div
                                style={{
                                  overflow: "auto",
                                  height: "250px",
                                }}
                              >
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>Client</StyledTableCell>
                                      <StyledTableCell>
                                        Txn Count
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {topCRListCountMap &&
                                      Object.keys(topCRListCountMap).map(
                                        (x) => {
                                          return (
                                            <StyledTableRow>
                                              <StyledTableCell>
                                                {topCRListCountMap[x].email}
                                              </StyledTableCell>
                                              <StyledTableCell>
                                                {(
                                                  Math.round(
                                                    topCRListCountMap[x]
                                                      .txnCount * 100
                                                  ) / 100
                                                ).toLocaleString()}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        }
                                      )}
                                  </TableBody>
                                </Table>
                              </div>
                            )}
                            {valueTopCR === 2 && (
                              <div
                                style={{
                                  overflow: "auto",
                                  height: "250px",
                                }}
                              >
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>Client</StyledTableCell>
                                      <StyledTableCell>
                                        Commission
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {topCRListComMap &&
                                      Object.keys(topCRListComMap).map((x) => {
                                        return (
                                          <StyledTableRow>
                                            <StyledTableCell>
                                              {topCRListComMap[x].email}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              USD{" "}
                                              {(
                                                Math.round(
                                                  topCRListComMap[x]
                                                    .commission * 100
                                                ) / 100
                                              ).toLocaleString()}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </div>
                            )}
                            {valueTopCR === 3 && (
                              <div
                                style={{
                                  overflow: "auto",
                                  height: "250px",
                                }}
                              >
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>Client</StyledTableCell>
                                      <StyledTableCell>Spread</StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {topCRListSpreadMap &&
                                      Object.keys(topCRListSpreadMap).map(
                                        (x) => {
                                          return (
                                            <StyledTableRow>
                                              <StyledTableCell>
                                                {topCRListSpreadMap[x].email}
                                              </StyledTableCell>
                                              <StyledTableCell>
                                                USD{" "}
                                                {(
                                                  Math.round(
                                                    topCRListSpreadMap[x]
                                                      .spread * 100
                                                  ) / 100
                                                ).toLocaleString()}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        }
                                      )}
                                  </TableBody>
                                </Table>
                              </div>
                            )}
                            {valueTopCR === 4 && (
                              <div
                                style={{
                                  overflow: "auto",
                                  height: "250px",
                                }}
                              >
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>Client</StyledTableCell>
                                      <StyledTableCell>
                                        Holding Cost
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {topCRListHoldingCostMap &&
                                      Object.keys(topCRListHoldingCostMap).map(
                                        (x) => {
                                          return (
                                            <StyledTableRow>
                                              <StyledTableCell>
                                                {
                                                  topCRListHoldingCostMap[x]
                                                    .email
                                                }
                                              </StyledTableCell>
                                              <StyledTableCell>
                                                USD{" "}
                                                {Math.round(
                                                  topCRListHoldingCostMap[x]
                                                    .totalRMSwap
                                                ).toLocaleString()}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        }
                                      )}
                                  </TableBody>
                                </Table>
                              </div>
                            )}
                          </>
                        )
                      ) : (
                        <h2>Loading...</h2>
                      )}
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "100%",
                        minHeight: "400px",
                      },
                    }}
                  >
                    <Paper sx={{ height: "300px" }}>
                      {value == 0 && (
                        <span
                          style={{
                            float: "right",
                            marginTop: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <CSVLink
                            filename="Top Users - Transaction Volume"
                            data={sortedUserListByVol || []}
                          >
                            <SystemUpdateAltIcon sx={{ color: "black" }} />
                          </CSVLink>
                        </span>
                      )}
                      {value == 1 && (
                        <span
                          style={{
                            float: "right",
                            marginTop: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <CSVLink
                            filename="Top Users - Transaction Count"
                            data={sortedUserListByTxn || []}
                          >
                            <SystemUpdateAltIcon sx={{ color: "black" }} />
                          </CSVLink>
                        </span>
                      )}
                      {value == 2 && (
                        <span
                          style={{
                            float: "right",
                            marginTop: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <CSVLink
                            filename="Top Users - Commissions & Spreads"
                            data={sortedUserListByCommission || []}
                          >
                            <SystemUpdateAltIcon sx={{ color: "black" }} />
                          </CSVLink>
                        </span>
                      )}
                      <h2>
                        Top Clients{" "}
                        <InfoPopover
                          content={DashboardTooltipItems["topClients"]}
                        />
                        <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                          {maskingState ? (
                            <VisibilityOffIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          )}
                        </span>
                      </h2>
                      {maskingState ? (
                        <h3
                          style={{
                            color: "goldenrod",
                            height: "250px !important",
                          }}
                        >
                          Data is Masked
                        </h3>
                      ) : (
                        <>
                          <Tabs
                            value={value}
                            onChange={(e, value) => {
                              setValue(value);
                            }}
                          >
                            <Tab
                              style={{ width: "20%", fontSize: "12px" }}
                              label="Volume"
                            />
                            <Tab
                              style={{ width: "20%", fontSize: "12px" }}
                              label="Txn Count"
                            />
                            <Tab
                              style={{ width: "20%", fontSize: "12px" }}
                              label="Commission"
                            />
                            <Tab
                              style={{ width: "20%", fontSize: "12px" }}
                              label="Spread"
                            />
                            <Tab
                              style={{ width: "20%", fontSize: "12px" }}
                              label="Holding"
                            />
                          </Tabs>
                          {value === 0 && (
                            <div
                              style={{
                                overflow: "auto",
                                height: "250px",
                              }}
                            >
                              <Table
                                stickyHeader
                                style={{
                                  tableLayout: "fixed",
                                }}
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>Client</StyledTableCell>
                                    <StyledTableCell>
                                      Txn Volume
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {sortedUserListByVol &&
                                    Object.keys(sortedUserListByVol).map(
                                      (x) => {
                                        return (
                                          <StyledTableRow>
                                            <StyledTableCell>
                                              {sortedUserListByVol[x].loginId}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              USD{" "}
                                              {(
                                                Math.round(
                                                  sortedUserListByVol[x]
                                                    .txnVolume * 100
                                                ) / 100
                                              ).toLocaleString()}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        );
                                      }
                                    )}
                                </TableBody>
                              </Table>
                            </div>
                          )}
                          {value === 1 && (
                            <div
                              style={{
                                overflow: "auto",
                                height: "250px",
                              }}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>Client</StyledTableCell>
                                    <StyledTableCell>Txn Count</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {sortedUserListByTxn &&
                                    Object.keys(sortedUserListByTxn).map(
                                      (x) => {
                                        return (
                                          <StyledTableRow>
                                            <StyledTableCell>
                                              {sortedUserListByTxn[x].loginId}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              {(
                                                Math.round(
                                                  sortedUserListByTxn[x]
                                                    .txnCount * 100
                                                ) / 100
                                              ).toLocaleString()}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        );
                                      }
                                    )}
                                </TableBody>
                              </Table>
                            </div>
                          )}
                          {value === 2 && (
                            <div
                              style={{
                                overflow: "auto",
                                height: "250px",
                              }}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>Client</StyledTableCell>
                                    <StyledTableCell>
                                      Commission
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {sortedUserListByCommission &&
                                    Object.keys(sortedUserListByCommission).map(
                                      (x) => {
                                        return (
                                          <StyledTableRow>
                                            <StyledTableCell>
                                              {
                                                sortedUserListByCommission[x]
                                                  .loginId
                                              }
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              USD{" "}
                                              {(
                                                Math.round(
                                                  sortedUserListByCommission[x]
                                                    .commission * 100
                                                ) / 100
                                              ).toLocaleString()}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        );
                                      }
                                    )}
                                </TableBody>
                              </Table>
                            </div>
                          )}
                          {value === 3 && (
                            <div
                              style={{
                                overflow: "auto",
                                height: "250px",
                              }}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>Client</StyledTableCell>
                                    <StyledTableCell>Spread</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {sortedUserListBySpread &&
                                    Object.keys(sortedUserListBySpread).map(
                                      (x) => {
                                        return (
                                          <StyledTableRow>
                                            <StyledTableCell>
                                              {
                                                sortedUserListBySpread[x]
                                                  .loginId
                                              }
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              USD{" "}
                                              {(
                                                Math.round(
                                                  sortedUserListBySpread[x]
                                                    .spread * 100
                                                ) / 100
                                              ).toLocaleString()}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        );
                                      }
                                    )}
                                </TableBody>
                              </Table>
                            </div>
                          )}
                          {value === 4 && (
                            <div
                              style={{
                                overflow: "auto",
                                height: "250px",
                              }}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>Client</StyledTableCell>
                                    <StyledTableCell>
                                      Holding Cost
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {holdingCostByClient &&
                                    Object.keys(holdingCostByClient).map(
                                      (x) => {
                                        return (
                                          <StyledTableRow>
                                            <StyledTableCell>
                                              {holdingCostByClient[x].loginId}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              USD{" "}
                                              {Math.round(
                                                holdingCostByClient[x]
                                                  .totalClientSwap
                                              ).toLocaleString()}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        );
                                      }
                                    )}
                                </TableBody>
                              </Table>
                            </div>
                          )}
                        </>
                      )}
                    </Paper>
                  </Box>
                </Grid>
              </Grid>

              <br></br>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    "& > :not(style)": {
                      width: "90%",
                      minHeight: "400px",
                      padding: "0 5%",
                    },
                  }}
                >
                  <Paper sx={{ height: "300px", position: "relative" }}>
                    <span style={{ float: "right", marginTop: "10px" }}>
                      <CSVLink
                        filename="Monthly Commissions & Spreads"
                        data={monthWiseCommissionSpread || []}
                      >
                        <SystemUpdateAltIcon sx={{ color: "black" }} />
                      </CSVLink>
                    </span>
                    <h3
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <InfoPopover
                        content={DashboardTooltipItems["comsAndSpreadMonthly"]}
                      />{" "}
                      Commissions & Spreads Monthly{" "}
                      <div style={{ marginLeft: "10px", cursor: "pointer" }}>
                        {maskingState ? (
                          <VisibilityOffIcon
                            onClick={() => {
                              setMaskingState(!maskingState);
                            }}
                          />
                        ) : (
                          <VisibilityIcon
                            onClick={() => {
                              setMaskingState(!maskingState);
                            }}
                          />
                        )}
                      </div>
                    </h3>
                    {maskingState ? (
                      <NoDataElement />
                    ) : monthWiseCommissionSpread &&
                      monthWiseCommissionSpread.length > 0 ? (
                      <StackedRadialBar
                        data={monthWiseCommissionSpread}
                        xField={"time"}
                        yField={"value"}
                        colorField={"type"}
                      />
                    ) : (
                      <NoDataElement />
                    )}
                  </Paper>
                </Box>
              </Grid> */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "100%",
                        minHeight: "400px",
                      },
                    }}
                  >
                    <Paper sx={{ height: "300px" }}>
                      <span style={{ float: "right", marginTop: "10px" }}>
                        <CSVLink
                          filename="Client Behaviour"
                          data={heatMapData || []}
                        >
                          <SystemUpdateAltIcon sx={{ color: "black" }} />
                        </CSVLink>
                      </span>
                      <h2>
                        Client Behaviour{" "}
                        <InfoPopover
                          content={DashboardTooltipItems["clientBehaviour"]}
                        />
                      </h2>
                      <Box sx={{ marginTop: -2, marginBottom: 1 }}>
                        <label>Filter by </label>
                        <select
                          id={"heatMap"}
                          defaultValue={"byTxnCount"}
                          style={{ fontSize: 14 }}
                          onChange={(e) => {
                            if (e.target.value == "byTxnHoldingCost") {
                              setHeatMapData(heatMapHoldingCostData);
                            } else {
                              getDistinctChartData("heatMap", e.target.value);
                            }
                          }}
                        >
                          <option value={"byTxnCount"}>Txn Count</option>
                          <option value={"byTxnVolume"}>Txn Volume</option>
                          <option value={"byDistinctUsers"}>
                            Distinct Users
                          </option>
                          <option value={"byTxnCommission"}>
                            By Commission
                          </option>
                          <option value={"byTxnSpread"}>By Spread</option>
                          <option value={"byTxnHoldingCost"}>
                            By Holding Cost
                          </option>
                        </select>
                      </Box>
                      {heatMapData && heatMapData.length > 0 ? (
                        <HeatMapComponent
                          data={heatMapData}
                          isLoading={heatMapLoading}
                          colorField={clientBehaviourColorField}
                        />
                      ) : (
                        <NoDataElement />
                      )}
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "90%",
                        minHeight: "400px",
                        padding: "0 5%",
                      },
                    }}
                  >
                    <Paper sx={{ height: "300px" }}>
                      <span style={{ float: "right", marginTop: "10px" }}>
                        <CSVLink
                          filename="Symbol Wise Transaction Count"
                          data={treeMapSymbolArray || []}
                        >
                          <SystemUpdateAltIcon sx={{ color: "black" }} />
                        </CSVLink>
                      </span>
                      <h3>
                        Symbol wise Transactions{" "}
                        <InfoPopover
                          content={DashboardTooltipItems["symbolWiseTxns"]}
                        />
                      </h3>
                      <Box sx={{ marginBottom: "10px" }}>
                        <label>Filter by </label>
                        <select
                          id={"treeMap"}
                          defaultValue={"byTxnCount"}
                          style={{ fontSize: 14 }}
                          onChange={(e) => {
                            if (e.target.value == "byTxnHoldingCost") {
                              setTreeMapSymbolArray(
                                treeMapSymbolArrayHoldingCost
                              );
                            } else {
                              getDistinctChartData("treeMap", e.target.value);
                            }
                          }}
                        >
                          <option value={"byTxnCount"}>Txn Count</option>
                          <option value={"byTxnVolume"}>Txn Volume</option>
                          <option value={"byDistinctUsers"}>
                            Distinct Users
                          </option>
                          <option value={"byTxnCommission"}>
                            By Commission
                          </option>
                          <option value={"byTxnSpread"}>By Spread</option>
                          <option value={"byTxnHoldingCost"}>
                            By Holding Cost
                          </option>
                        </select>
                      </Box>
                      {treeMapSymbolArray && treeMapSymbolArray.length > 0 ? (
                        <TreeMapComponent
                          data={treeMapSymbolArray}
                          isLoading={treeMapLoading}
                          colorField={"name"}
                        />
                      ) : (
                        <NoDataElement />
                      )}
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={5}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "90%",
                        minHeight: "400px",
                        minHeight: "400px",
                        padding: "0 5%",
                      },
                    }}
                  >
                    <Paper sx={{ height: "300px" }}>
                      <h2>
                        Active clients vs Passive clients{" "}
                        <InfoPopover
                          content={DashboardTooltipItems["actVsPassClients"]}
                        />
                      </h2>
                      <span style={{ fontSize: "12px" }}>
                        Click to view clients
                      </span>
                      {totAndActClients &&
                      totAndActClients.length &&
                      clientList.length > 0 ? (
                        platformType == "TU" ? (
                          <AreaChartComponent
                            data={totAndActClients}
                            encryptedData={uproDataEncrypted}
                            userEmail={userEmail}
                            clientList={clientList}
                            values={filterValues.current}
                            setGridDataTitle={setGridDataTitle}
                            setDialogDataGrid={setGridData}
                            platformType={"TU"}
                            onOpenDataGrid={() => {
                              setIsChartDataGridOpen(true);
                            }}
                            xField={"date"}
                            yField={"count"}
                            series={"type"}
                          />
                        ) : (
                          <AreaChartComponentCFC
                            data={totAndActClients}
                            encryptedData={uproDataEncrypted}
                            userEmail={userEmail}
                            clientList={clientList}
                            values={filterValues.current}
                            setGridDataTitle={setGridDataTitle}
                            setDialogDataGrid={setGridData}
                            platformType={"CFC"}
                            onOpenDataGrid={() => {
                              setIsChartDataGridOpen(true);
                            }}
                            xField={"date"}
                            yField={"count"}
                            series={"type"}
                          />
                        )
                      ) : (
                        <NoDataElement />
                      )}
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: 2,
                  // display: platformType == "TU" ? "flex" : "none",
                }}
              >
                <Grid item lg={6} xl={6} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "90%",
                        minHeight: "500px",
                        padding: "0 5%",
                      },
                    }}
                  >
                    <Paper sx={{ minHeight: "500px", maxHeight: "500px" }}>
                      <span
                        style={{ float: "right", marginTop: "10px" }}
                      ></span>
                      <h2>
                        Funding Statistics{" "}
                        <InfoPopover
                          content={DashboardTooltipItems["fundingStats"]}
                        />
                      </h2>
                      <Box
                        sx={{
                          alignContent: "center",
                          backgroundColor: "#ffd700",
                          height: "34px",
                          display: "inline-block",
                          p: 1,
                        }}
                      >
                        <h5
                          style={{
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          Please Note: Map client data on right platform on Upro
                          for accurate data
                        </h5>
                      </Box>
                      <br></br>
                      <br></br>
                      <Grid container spacing={platformType == "TU" ? 2 : 1}>
                        <Grid item xs={platformType == "TU" ? 6 : 12}>
                          <Paper
                            style={{
                              padding: platformType == "TU" ? "5%" : "3%",
                              backgroundColor: "#111",
                              color: "gold",
                            }}
                          >
                            <p>Total Deposits</p>
                            <h2 style={{ color: "white" }}>
                              USD{" "}
                              {fundingStatistics &&
                                Math.round(
                                  fundingStatistics.deposits
                                ).toLocaleString()}
                            </h2>
                          </Paper>
                        </Grid>
                        <Grid item xs={platformType == "TU" ? 6 : 12}>
                          <Paper
                            style={{
                              padding: platformType == "TU" ? "5%" : "3%",
                              backgroundColor: "#111",
                              color: "gold",
                            }}
                          >
                            <p>Total Withdrawals</p>
                            <h2 style={{ color: "white" }}>
                              USD{" "}
                              {fundingStatistics &&
                                (
                                  Math.round(fundingStatistics.withdrawals) * -1
                                ).toLocaleString()}
                            </h2>
                          </Paper>
                        </Grid>
                      </Grid>
                      <br></br>
                      {platformType == "TU" && (
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Paper
                              style={{
                                padding: "5%",
                                backgroundColor: "#111",
                                color: "gold",
                              }}
                            >
                              <p>Total Credits In</p>
                              <h2 style={{ color: "white" }}>
                                USD{" "}
                                {fundingStatistics &&
                                  Math.round(
                                    fundingStatistics.creditIns
                                  ).toLocaleString()}
                              </h2>
                            </Paper>
                          </Grid>
                          <Grid item xs={6}>
                            <Paper
                              style={{
                                padding: "5%",
                                backgroundColor: "#111",
                                color: "gold",
                              }}
                            >
                              <p>Total Credits Out</p>
                              <h2 style={{ color: "white" }}>
                                USD{" "}
                                {fundingStatistics &&
                                  (
                                    Math.round(fundingStatistics.creditOuts) *
                                    -1
                                  ).toLocaleString()}
                              </h2>
                            </Paper>
                          </Grid>
                        </Grid>
                      )}
                    </Paper>
                  </Box>
                </Grid>
                <Grid item lg={6} xl={6} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "90%",
                        minHeight: "500px",
                        padding: "0 5%",
                      },
                    }}
                  >
                    <Paper sx={{ minHeight: "500px", maxHeight: "500px" }}>
                      <span style={{ float: "right", marginTop: "10px" }}>
                        <CSVLink
                          filename="Funding Transaction"
                          data={fundingTxns || []}
                        >
                          <SystemUpdateAltIcon sx={{ color: "black" }} />
                        </CSVLink>
                      </span>

                      <h2>
                        Funding Transactions{" "}
                        <InfoPopover
                          content={DashboardTooltipItems["fundingTxns"]}
                        />
                      </h2>
                      <Box
                        sx={{
                          alignContent: "center",
                          backgroundColor: "#ffd700",
                          height: "34px",
                          display: "inline-block",
                          p: 1,
                        }}
                      >
                        <h5
                          style={{
                            margin: "0",
                            padding: "0",
                            textAlign: "left",
                          }}
                        >
                          Please Note: Map client data on right platform on Upro
                          for accurate data
                        </h5>
                      </Box>
                      <br />
                      <br />
                      {fundingTxns && (
                        <DataGrid
                          style={{ height: "350px" }}
                          getRowId={(row) => row.id}
                          columns={[
                            { field: "Transaction Time", flex: 1 },
                            { field: "Login Id", flex: 1 },
                            { field: "Client Name", flex: 1 },
                            { field: "Type", flex: 1 },
                            { field: "Amount", flex: 1 },
                            { field: "Currency", flex: 1 },
                          ]}
                          rows={fundingTxns}
                          components={{
                            Toolbar: GridToolbar,
                          }}
                        />
                      )}
                      <br></br>
                      <br></br>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 2 }}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        width: "90%",
                        minHeight: "400px",
                        minHeight: "400px",
                        padding: "0 5%",
                      },
                    }}
                  >
                    <Paper sx={{ minHeight: "500px" }}>
                      <span style={{ float: "right", marginTop: "10px" }}>
                        <CSVLink
                          filename="User Transaction Summary"
                          data={txnData || []}
                        >
                          <SystemUpdateAltIcon sx={{ color: "black" }} />
                        </CSVLink>
                      </span>

                      <h2 style={{ display: "flex", justifyContent: "middle" }}>
                        Transaction Dump{" "}
                        <div style={{ marginLeft: "10px", cursor: "pointer" }}>
                          {maskingState ? (
                            <VisibilityOffIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          )}
                        </div>
                      </h2>

                      <br />
                      {txnData && !maskingState && (
                        <DataGrid
                          style={{ height: "800px" }}
                          getRowId={(row) => row.id}
                          columns={[
                            { field: "LoginId", flex: 1 },
                            { field: "Name", flex: 1 },
                            { field: "OrderId", flex: 1 },
                            { field: "PositionId", flex: 1 },
                            { field: "Country", flex: 1 },
                            { field: "Symbol", flex: 1 },
                            { field: "Time", flex: 1 },
                            { field: "Entry", flex: 1 },
                            { field: "Type", flex: 1 },
                            {
                              field: "Units",
                              flex: 1,
                              renderCell: (param) => {
                                return param.row.Units.toLocaleString();
                              },
                            },
                            {
                              field: "Price",
                              flex: 1,
                              renderCell: (param) => {
                                return param.row.Price.toLocaleString();
                              },
                            },
                            {
                              field: "Amount",
                              flex: 1,
                              renderCell: (param) => {
                                return param.row.Amount.toLocaleString();
                              },
                            },
                            { field: "Currency", flex: 1 },
                            { field: "Commission", flex: 1 },
                            {
                              field: "Spread",
                              flex: 1,
                              renderCell: (param) => {
                                return param.row.Spread.toFixed(
                                  2
                                ).toLocaleString();
                              },
                            },
                            { field: "MetaCommission", flex: 1 },
                          ]}
                          rows={txnData}
                          components={{
                            Toolbar: GridToolbar,
                          }}
                        />
                      )}
                      {txnData && maskingState && (
                        <DataGrid
                          style={{ height: "800px" }}
                          getRowId={(row) => row.id}
                          columns={[
                            { field: "LoginId", flex: 1 },
                            { field: "OrderId", flex: 1 },
                            { field: "PositionId", flex: 1 },
                            { field: "Country", flex: 1 },
                            { field: "Symbol", flex: 1 },
                            { field: "Time", flex: 1 },
                            { field: "Entry", flex: 1 },
                            { field: "Type", flex: 1 },
                            {
                              field: "Units",
                              flex: 1,
                              renderCell: (param) => {
                                return param.row.Units.toLocaleString();
                              },
                            },
                            {
                              field: "Price",
                              flex: 1,
                              renderCell: (param) => {
                                return param.row.Price.toLocaleString();
                              },
                            },
                            {
                              field: "Amount",
                              flex: 1,
                              renderCell: (param) => {
                                return param.row.Amount.toLocaleString();
                              },
                            },
                            { field: "Currency", flex: 1 },
                          ]}
                          rows={txnData}
                          components={{
                            Toolbar: GridToolbar,
                          }}
                        />
                      )}
                      <br></br>
                      <br></br>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* <div>
                  <BarChartComponent data={txnData} xField={"time"} yField={"value"} />
                  <StackedBarLineChartComponent data={txnData} xField={"time"} yFieldLine={"count"} yFieldBar={"value"} stackField={"type"} />
              </div>
              <div>
                  <AreaChartComponent data={txnData} xField={"date"} yField={"commission"} seriesField={"loginId"} />
              </div> */}
        </div>
      )}
    </>
  );
}
function BarChartCompnent(props) {
  function getFormattedStatValue(value) {
    let val = null;

    if (value < 1000) {
      val = value;
    } else if (value >= 1000 && value < 1000000) {
      val = Math.round(value / 1000).toLocaleString() + "K";
    } else if (value >= 1000000) {
      val = Math.round(value / 1000000).toLocaleString() + "M";
    }
    return val;
  }
  const [data, setData] = useState(props.chartData);

  const config = {
    data: data.reverse(),
    xField: "value",
    yField: "time",
    isStack: true,
    seriesField: "type",
    color: ["gold", "#111", "#f7ed7e"],
    yAxis: {
      grid: { line: { style: 0 } },
      label: {
        formatter: (label) => {
          return moment(label).format("MMMM YYYY");
        },
      },
    },
    xAxis: {
      grid: { line: { style: 0 } },
      label: {
        formatter: (label) => {
          return getFormattedStatValue(label);
        },
      },
    },

    tooltip: {
      title: (value) => new moment(value).format("MMMM YYYY"),
      customItems: (originalItems) => {
        originalItems[0].name = "Holding Cost";
        originalItems[0].value = getFormattedStatValue(originalItems[0].value);
        originalItems[1].name = "Spread";
        originalItems[1].value = getFormattedStatValue(originalItems[1].value);
        originalItems[2].name = "Commission";
        originalItems[2].value = getFormattedStatValue(originalItems[2].value);
        return originalItems;
      },

      showContent: true,
    },
    label: {
      formatter: (label) => {
        return getFormattedStatValue(label.value);
      },
      position: "top",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
  };
  return <Bar {...config} />;
}
function NoDataElement() {
  return (
    <>
      <br />
      <br />
      <br />
      <h2 style={{ color: "goldenrod" }}>No Data Available</h2>
    </>
  );
}

function InfoPopover(props) {
  const { content, color = "black" } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton aria-describedby={id} size="small" onClick={handleClick}>
        <InfoOutlinedIcon size="small" sx={{ color }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>{content}</Typography>
      </Popover>
    </>
  );
}
