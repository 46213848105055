import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import loader from "../../images/loader.gif"
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Popover,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  Typography,
  circularProgressClasses
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import { forEach, groupBy } from "lodash-es";
import axios from "axios";
import { JSEncrypt } from "jsencrypt";
import React, { useEffect, useRef, useState } from "react";
import StatCard from "../../components/global/statCard-component";
import { Buffer } from "buffer";
import { CSVLink, CSVDownload } from "react-csv";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  GET_PUBLIC_KEY, CHECK_ALLOWED_USERS
} from "../../helpers/apiStrings";
import { useNavigate, useSearchParams } from "react-router-dom";
import { config } from "../../helpers/config";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  BASE_URL,
  GET_TOP_SYMBOLS_DATA,
  GET_PLATFORM_WISE_DATA,
  GET_SYMBOL_WISE_DATA,
  GET_ALL_TRANSACTION_STATS,
  GET_FILE_READER_ADMIN_REVAMP
} from "../../helpers/adminApiStrings";

import { Bar, Column, Line } from '@ant-design/charts';
import CustomBarChart from "./components/customBarChart";
import CustomLineChart from "./components/customLineChart";
import { Divider } from "antd";
import DateRangeTimePicker from "../../components/global/dateRangeTimePicker";
import CustomMultiSelector from "../../components/customMultiSelector";
import CustomMultiSelector1 from "../../components/customMultiSelector1";
import ColumnGroup from "antd/es/table/ColumnGroup";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "black",
  display: "flex",
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#04832a" : "#04832a",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));


const statCardGridSize = { xl: 3, lg: 3, md: 4, sm: 6, xs: 12 };
const encrypt = new JSEncrypt();

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUri,
    authority: config.authority,
    postLogoutRedirectUri: config.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
});
export default function NewMgmtHome(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState();
  const [lineChartForSymbol, setLineChartForSymbol] = useState([]);//used
  const [statistics, setStatistics] = useState([]);//used
  const [emailList, setEmailList] = useState([]);//used
  const [clientList, setclientList] = useState([]);//used
  const [lineChartValuesPlatform, setLineChartValuesPlatform] = useState([]);//used
  const [barChartForTopSymbols, setBarChartForTopSymbols] = useState([]);//used
  const [symbolArray, setSymbolArray] = useState([]);////////
  const [tempListSymbol, setTempListSymbol] = useState([]);////////
  const [addedSymbolArray, setAddedSymbolArray] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [applySymbolFilter, setApplySymbolFilter] = useState(false);///////
  const [authenticated, setAuthenticated] = useState(false);
  const [name, setName] = useState("");
  const [defaultValueSymbolFilter, setDefaultValueSymbolFilter] = useState("All");
  const [selectedMode, setSelectedMode] = useState("By Count");//used
  const [selectedDisplayType, setSelectedDisplayType] = useState("By Percentage");//used
  const [symbolWiseFilterForGraph, setSymbolWiseFilterForGraph] = useState("Weekly");//used
  const [platformWiseFilterForGraph, setPlatformWiseFilterForGraph] = useState("Weekly");//used
  const [topSymbolsFilterForBarChart, setTopSymbolsFilterForBarChart] = useState("Last Week");//used
  const [dateRange, setDateRange] = useState([]);//used
  const [selectedArray, setSelectedArray] = useState([]);//used
  const [tempList, setTempList] = useState([]);//used
  const [selectedArraySymbol, setSelectedArraySymbol] = useState([]);//used
  const [openDropDown, setOpenDropDown] = useState(false);//used
  const [openDropDownSymbol, setOpenDropDownSymbol] = useState(false);//used
  const [cachedData, setCachedData] = useState({});
  const [emailAccountsList, setEmailAccountsList] = useState({});//used
  const [fileReaderData, setFileReaderData] = useState();//used
  const filterValues = useRef({
    client: [],
    symbol: [],
    startDate: "",
    endDate: "",
    user: "All"
  });

  const [isLoadingPlatformWiseData, setIsLoadingPlatformWiseData] = useState(false);
  const [isLoadingTopSymbolData, setIsLoadingTopSymbolData] = useState(false);
  const [isLoadingSymbolData, setIsLoadingSymbolData] = useState(false);
  const [isLoadingStatData, setIsLoadingStatData] = useState(false);
  const [platformType, setPlatformType] = useState("CFC");
  function checkAuthorization(userEmail, name) {
    //CAU = century admin users
    setIsLoading(true);
    const enc = encrypt.encrypt(JSON.stringify({ userType: "CAU", userEmail }));
    axios
      .post(CHECK_ALLOWED_USERS, { payload: enc })
      .then((res) => {
        setName(name);
        setAuthenticated(true);
        setIsLoading(false);
        getTxns();
      })
      .catch((e) => {
        setIsLoading(false);
        setName(name);

        setAuthenticated(false);
      });
  }
  function getPublicKey() {
    setIsLoading(true);
    axios
      .get(GET_PUBLIC_KEY)
      .then((res) => {
        setIsLoading(false);
        const d = Buffer.from(res.data, "base64").toString("ascii");
        encrypt.setPublicKey(d);
        let accounts = publicClientApplication.getAllAccounts();
        if (accounts.length == 0) {
          console.log("Not Signed in");
          navigate("/adminrevamp/login");
        } else {
          checkAuthorization(accounts[0].username, accounts[0].name);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e.message);
      });
  }
  useEffect(() => {
    getPublicKey();
    getFileReaderData()

  }, [platformType]);//, selectedMode, selectedDisplayType
  const ParentFilters = (stringParam) => {
    setPlatformWiseFilterForGraph("Weekly")
    setSymbolWiseFilterForGraph("Weekly")
    setTopSymbolsFilterForBarChart("Last Week")
    if (!fileReaderData) {
      if (stringParam == "By Distinct User") {
        setSelectedMode(stringParam)
      }
      else if (stringParam == "By Volume") {
        setSelectedMode(stringParam)
      }
      else {
        setSelectedMode(stringParam)
      }
      lineGraphForPatform("Weekly")
      lineChartForSymbol("Weekly")
    }
    else {
      setSelectedMode(stringParam)
      switchLineData("Weekly")
      fileReaderPlatformWiseData("Weekly", stringParam, selectedDisplayType, fileReaderData)
      fileReaderSymbolWiseData("Weekly", stringParam, selectedDisplayType, fileReaderData)
    }
  }
  const displayType = (stringParam) => {
    console.log(!fileReaderData)
    if (!fileReaderData) {
      if (stringParam == "By Value") {
        setSelectedDisplayType(stringParam)
      }
      else if (stringParam == "By Percentage") {
        setSelectedDisplayType(stringParam)
      }
      lineGraphForPatform("Weekly")
      lineGraphForSymbols("Weekly")
    } else {
      setSelectedDisplayType(stringParam)
      switchLineData("Weekly")
      fileReaderPlatformWiseData("Weekly", selectedMode, stringParam, fileReaderData)//here weekly can also be dynamic
      fileReaderSymbolWiseData("Weekly", selectedMode, stringParam, fileReaderData)
    }
  }
  const getFileReaderData = () => {
    try {
      axios.post(BASE_URL + "/" + platformType + GET_FILE_READER_ADMIN_REVAMP, { platformType }).then((res) => {
        let getAllTransactionPlatformWise = JSON.parse(((res.data.getAllTransactionPlatformWise).replaceAll("'", '"')).replaceAll("None", "null"))
        let getAllTransactionSymbolWise = JSON.parse(((res.data.getAllTransactionSymbolWise).replaceAll("'", '"')).replaceAll("None", "null"))
        let data = { getAllTransactionPlatformWise: getAllTransactionPlatformWise, getAllTransactionSymbolWise: getAllTransactionSymbolWise }
        if (getAllTransactionPlatformWise != undefined && getAllTransactionSymbolWise != undefined) {
          setFileReaderData(data)
          fileReaderPlatformWiseData("Weekly", "By Count", "By Percentage", data)
          fileReaderSymbolWiseData("Weekly", "By Count", "By Percentage", data)
        } else {
          getTxns();
        }
      }).catch(e => {
        console.log("ERROR: ", e)
      })
    }
    catch (e) {
      console.log("ERROR")
    }
  }
  function fileReaderPlatformWiseData(timeParam, mode, display, data) {
    function dataLoader(isLoading, setValue, value) {
      setValue([])
      isLoading(false);
      setValue(value);
      isLoading(true);
    }
    //weekly
    try {
      if (mode == "By Count" && display == "By Percentage" && timeParam == "Weekly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.countPercentWeekTrade)
      }
      else if (mode == "By Volume" && display == "By Percentage" && timeParam == "Weekly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.volumePercentWeekTrade)
      }
      else if (mode == "By Distinct User" && display == "By Percentage" && timeParam == "Weekly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.distinctUserPercentWeekTrade)
      }
      else if (mode == "By Count" && display == "By Value" && timeParam == "Weekly") {
        console.log("testing I m here")
        console.log(data.getAllTransactionPlatformWise.countValueWeekTrade)
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.countValueWeekTrade)
      }
      else if (mode == "By Volume" && display == "By Value" && timeParam == "Weekly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.volumeValueWeekTrade)
      }
      else if (mode == "By Distinct User" && display == "By Value" && timeParam == "Weekly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.distinctUserValueWeekTrade)
      }
      //monthly
      else if (mode == "By Count" && display == "By Percentage" && timeParam == "Monthly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.countPercentMonthTrade)
      }
      else if (mode == "By Volume" && display == "By Percentage" && timeParam == "Monthly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.volumePercentMonthTrade)
      }
      else if (mode == "By Distinct User" && display == "By Percentage" && timeParam == "Monthly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.distinctUserPercentMonthTrade)
      }
      else if (mode == "By Count" && display == "By Value" && timeParam == "Monthly") {
        console.log("testing")
        console.log(data.getAllTransactionPlatformWise.countValueWeekTrade)
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.countValueMonthTrade)
      }
      else if (mode == "By Volume" && display == "By Value" && timeParam == "Monthly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.volumeValueMonthTrade)
      }
      else if (mode == "By Distinct User" && display == "By Value" && timeParam == "Monthly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.distinctUserValueMonthTrade)
      }
      //quarterly
      else if (mode == "By Count" && display == "By Percentage" && timeParam == "Quarterly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.countPercentQuarterTrade)
      }
      else if (mode == "By Volume" && display == "By Percentage" && timeParam == "Quarterly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.volumePercentQuarterTrade)
      }
      else if (mode == "By Distinct User" && display == "By Percentage" && timeParam == "Quarterly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.distinctUserPercentQuarterTrade)
      }
      else if (mode == "By Count" && display == "By Value" && timeParam == "Quarterly") {
        console.log("testing")
        console.log(data.getAllTransactionPlatformWise.countValueWeekTrade)
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.countValueQuarterTrade)
      }
      else if (mode == "By Volume" && display == "By Value" && timeParam == "Quarterly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.volumeValueQuarterTrade)
      }
      else if (mode == "By Distinct User" && display == "By Value" && timeParam == "Quarterly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.distinctUserValueQuarterTrade)
      }
      //yearly
      else if (mode == "By Count" && display == "By Percentage" && timeParam == "Yearly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.countPercentYearTrade)
      }
      else if (mode == "By Volume" && display == "By Percentage" && timeParam == "Yearly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.volumePercentYearTrade)
      }
      else if (mode == "By Distinct User" && display == "By Percentage" && timeParam == "Yearly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.distinctUserPercentYearTrade)
      }
      else if (mode == "By Count" && display == "By Value" && timeParam == "Yearly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.countValueYearTrade)
      }
      else if (mode == "By Volume" && display == "By Value" && timeParam == "Yearly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.volumeValueYearTrade)
      }
      else if (mode == "By Distinct User" && display == "By Value" && timeParam == "Yearly") {
        dataLoader(setIsLoadingPlatformWiseData, setLineChartValuesPlatform, data.getAllTransactionPlatformWise.distinctUserValueYearTrade)
      }

      if (!isLoadingStatData) {
        barGraphForTopSymbols(topSymbolsFilterForBarChart);
        getStatistics()
      }
    }
    catch (e) {
      console.log("ERROR file reader symbol wise data : ", e)
    }
  }
  function fileReaderSymbolWiseData(timeParam, mode, display, data) {
    function dataLoader(isLoading, setValue, value) {
      setValue([])
      isLoading(false);
      setValue(value);
      isLoading(true);
    }
    setSymbolWiseFilterForGraph(timeParam)
    //weekly

    if (mode == "By Count" && display == "By Percentage" && timeParam == "Weekly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.countPercentWeekTrade)
    }
    else if (mode == "By Volume" && display == "By Percentage" && timeParam == "Weekly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.volumePercentWeekTrade)
    }
    else if (mode == "By Distinct User" && display == "By Percentage" && timeParam == "Weekly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.distinctUserPercentWeekTrade)
    }
    else if (mode == "By Count" && display == "By Value" && timeParam == "Weekly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.countValueWeekTrade)
    }
    else if (mode == "By Volume" && display == "By Value" && timeParam == "Weekly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.volumeValueWeekTrade)
    }
    else if (mode == "By Distinct User" && display == "By Value" && timeParam == "Weekly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.distinctUserValueWeekTrade)
    }
    //monthly
    else if (mode == "By Count" && display == "By Percentage" && timeParam == "Monthly") {
      console.log("from the inner method")
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.countPercentMonthTrade)
    }
    else if (mode == "By Volume" && display == "By Percentage" && timeParam == "Monthly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.volumePercentMonthTrade)
    }
    else if (mode == "By Distinct User" && display == "By Percentage" && timeParam == "Monthly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.distinctUserPercentMonthTrade)
    }
    else if (mode == "By Count" && display == "By Value" && timeParam == "Monthly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.countValueMonthTrade)
    }
    else if (mode == "By Volume" && display == "By Value" && timeParam == "Monthly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.volumeValueMonthTrade)
    }
    else if (mode == "By Distinct User" && display == "By Value" && timeParam == "Monthly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.distinctUserValueMonthTrade)
    }
    //quarterly
    else if (mode == "By Count" && display == "By Percentage" && timeParam == "Quarterly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.countPercentQuarterTrade)
    }
    else if (mode == "By Volume" && display == "By Percentage" && timeParam == "Quarterly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.volumePercentQuarterTrade)
    }
    else if (mode == "By Distinct User" && display == "By Percentage" && timeParam == "Quarterly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.distinctUserPercentQuarterTrade)
    }
    else if (mode == "By Count" && display == "By Value" && timeParam == "Quarterly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.countValueQuarterTrade)
    }
    else if (mode == "By Volume" && display == "By Value" && timeParam == "Quarterly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.volumeValueQuarterTrade)
    }
    else if (mode == "By Distinct User" && display == "By Value" && timeParam == "Quarterly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.distinctUserValueQuarterTrade)
    }
    //yearly
    else if (mode == "By Count" && display == "By Percentage" && timeParam == "Yearly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.countPercentYearTrade)
    }
    else if (mode == "By Volume" && display == "By Percentage" && timeParam == "Yearly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.volumePercentYearTrade)
    }
    else if (mode == "By Distinct User" && display == "By Percentage" && timeParam == "Yearly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.distinctUserPercentYearTrade)
    }
    else if (mode == "By Count" && display == "By Value" && timeParam == "Yearly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.countValueYearTrade)
    }
    else if (mode == "By Volume" && display == "By Value" && timeParam == "Yearly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.volumeValueYearTrade)
    }
    else if (mode == "By Distinct User" && display == "By Value" && timeParam == "Yearly") {
      dataLoader(setIsLoadingSymbolData, setLineChartForSymbol, data.getAllTransactionSymbolWise.distinctUserValueYearTrade)
    }
  }

  const switchGraphData = (stringParam) => {
    setBarChartForTopSymbols([])
    if (stringParam == "Last Week") {
      setTopSymbolsFilterForBarChart(stringParam)
      barGraphForTopSymbols(stringParam)
    }
    else if (stringParam == "Last Quarter") {
      setTopSymbolsFilterForBarChart(stringParam)
      barGraphForTopSymbols(stringParam)
    }
    else if (stringParam == "Last Quarter") {
      setTopSymbolsFilterForBarChart(stringParam)
      barGraphForTopSymbols(stringParam)
    }
    else {
      setTopSymbolsFilterForBarChart(stringParam)
      barGraphForTopSymbols(stringParam)
    }
  }
  const switchLineDataForsymbol = (stringParam) => {
    setLineChartForSymbol([])
    if (!fileReaderData) {
      if (stringParam == "Weekly") {
        setSymbolWiseFilterForGraph(stringParam)
        lineGraphForSymbols(stringParam)
      }
      else if (stringParam == "Monthly") {
        setSymbolWiseFilterForGraph(stringParam)
        lineGraphForSymbols(stringParam)
      }
      else if (stringParam == "Yearly") {
        setSymbolWiseFilterForGraph(stringParam)
        lineGraphForSymbols(stringParam)
      }
      else {
        setSymbolWiseFilterForGraph(stringParam)
        lineGraphForSymbols(stringParam)
      }
    }
    else {
      setLineChartForSymbol(stringParam)
      fileReaderSymbolWiseData(stringParam, selectedMode, selectedDisplayType, fileReaderData)
    }
  }
  const switchLineData = (stringParam) => {
    setLineChartValuesPlatform([])
    if (!fileReaderData) {
      if (stringParam == "Weekly") {
        setPlatformWiseFilterForGraph(stringParam)
        lineGraphForPatform(stringParam)
      }
      else if (stringParam == "Monthly") {
        setPlatformWiseFilterForGraph(stringParam)
        lineGraphForPatform(stringParam)
      }
      else if (stringParam == "Yearly") {
        setPlatformWiseFilterForGraph(stringParam)
        lineGraphForPatform(stringParam)
      }
      else if (stringParam == "Quarterly") {
        setPlatformWiseFilterForGraph(stringParam)
        lineGraphForPatform(stringParam)
      }
    }
    else {
      setPlatformWiseFilterForGraph(stringParam)
      fileReaderPlatformWiseData(stringParam, selectedMode, selectedDisplayType, fileReaderData)
    }
  }
  const getTxns = () => {
    filterValues.current.client = selectedArray;
    filterValues.current.symbol = selectedArraySymbol;
    if (document.getElementById("dropdownviewSymbol") && document.getElementById("dropdownviewSymbol").innerHTML == "All") {
      filterValues.current.symbol = ["All"]
      setTempListSymbol(["All"].concat(symbolArray))
      setSelectedArraySymbol(["All"].concat(symbolArray))
    }
    if (document.getElementById("dropdownview") && document.getElementById("dropdownview").innerHTML == "All") {
      if (filterValues.current.user == "All") {
        filterValues.current.client = ["All"]
      }
      else {
        filterValues.current.client = selectedArray.filter(item => {
          if (item != "All")
            return parseInt(item)
        })
        filterValues.current.client = filterValues.current.client.map(Number)
      }
      setTempList(["All"].concat(clientList))
      setSelectedArray(["All"].concat(clientList))
    }
    lineGraphForPatform(platformWiseFilterForGraph);
    lineGraphForSymbols(symbolWiseFilterForGraph);
    barGraphForTopSymbols(topSymbolsFilterForBarChart);
    getStatistics()
    setIsLoadingPlatformWiseData(false);
    setIsLoadingTopSymbolData(false);
    setIsLoadingSymbolData(false);
  };

  const lineGraphForPatform = (param, parentFilter = selectedMode, displayType = selectedDisplayType) => {
    setIsLoadingPlatformWiseData(false);
    console.log(parentFilter)
    console.log(selectedMode)
    axios
      .post(BASE_URL + "/" + platformType + GET_PLATFORM_WISE_DATA, {
        parentFilter: parentFilter,
        displayType: displayType,
        platformBy: param,
        values: filterValues.current,
      })
      .then((resp) => {
        setLineChartValuesPlatform(resp.data)
        setIsLoadingPlatformWiseData(true);
      }).catch((err) => {
        console.log(err);

      });
  }
  const barGraphForTopSymbols = (param) => {
    setIsLoadingTopSymbolData(false);
    axios
      .post(BASE_URL + "/" + platformType + GET_TOP_SYMBOLS_DATA, {
        parentFilter: selectedMode,
        topSymbolBy: param,
        values: filterValues.current,
      })
      .then((resp) => {
        setBarChartForTopSymbols(resp.data)
        setIsLoadingTopSymbolData(true);
      }).catch((err) => {
        console.log(err);

      });
  }
  const lineGraphForSymbols = (param) => {
    setIsLoadingSymbolData(false);
    axios
      .post(BASE_URL + "/" + platformType + GET_SYMBOL_WISE_DATA, {
        parentFilter: selectedMode,
        symbolWiseBy: param,
        addedSymbolArray: addedSymbolArray,
        displayType: selectedDisplayType,
        values: filterValues.current,
      })
      .then((resp) => {
        setLineChartForSymbol(resp.data)
        setIsLoadingSymbolData(true);
      }).catch((err) => {
        console.log(err);

      });
  }
  const getStatistics = () => {
    setIsLoadingStatData(false);
    axios
      .post(BASE_URL + "/" + platformType + GET_ALL_TRANSACTION_STATS, {
        values: filterValues.current,
      })
      .then((resp) => {
        setStatistics(resp.data[0])
        setEmailList(resp.data[0]["emailList"])
        setclientList(resp.data[0]["clientList"])
        if (filterValues.current.client.length == 0) {
          setSelectedArray(["All"].concat(resp.data[0]["clientList"]))
        }
        setTempList(["All"].concat(resp.data[0]["clientList"]))
        setEmailAccountsList(resp.data[0]["emailAccounts"])


        setSymbolArray(resp.data[0]["symbolList"])
        if (filterValues.current.symbol.length == 0) {
          setSelectedArraySymbol(["All"].concat(resp.data[0]["symbolList"]))
        }
        setTempListSymbol(["All"].concat(resp.data[0]["symbolList"]))
        setIsLoadingStatData(true);
      }).catch((err) => {
        console.log(err);

      });
  }
  const togglePlatformType = () => {
    filterValues.current.startDate = "";
    filterValues.current.endDate = "";
    filterValues.current.user = "All";
    filterValues.current.client = [];
    filterValues.current.symbol = [];
    setIsLoadingStatData(false)
    setStatistics([])
    setDateRange(null)
    if (platformType == "TU") {
      setCachedData({});
      setPlatformType("CFC");
    } else {
      setCachedData({});
      setPlatformType("TU");
    }
  };
  function handleValueChange(e) {
    let old = filterValues.current;
    old[e.target.name] = e.target.value;
    if (e.target.name === "client") {
      old[e.target.name] = selectedArray;
    }
    filterValues.current = old;
    if (e.target.name === "user") {
      if (e.target.value !== "All") {
        setTempList(["All"].concat(emailAccountsList[e.target.value].accountList));
        setSelectedArray(["All"].concat(emailAccountsList[e.target.value].accountList))
      }
      else {
        setTempList(["All"].concat(clientList));
        setSelectedArray(["All"].concat(clientList))
      }
    } else if (e.target.name === "symbol") {
      old[e.target.name] = selectedArraySymbol;
    } else if (e.target.name === "startDate") {
    } else if (e.target.name === "endDate") {
    }
  }
  function resetFilter() {
    getFileReaderData()
    document.getElementById("dropdownview").innerHTML = "All"
    document.getElementById("dropdownviewSymbol").innerHTML = "All"
    filterValues.current.user = "All"
    filterValues.current = {
      client: clientList,
      symbol: symbolArray,
      startDate: "",
      endDate: "",
      user: "All",
    };
    setSelectedArray(["All"].concat(clientList))
    setSelectedArraySymbol(["All"].concat(symbolArray))
    setDateRange(null);
    getTxns();
  }

  function logout() {
    try {
      localStorage.clear();
      sessionStorage.clear();
      (function () {
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split(".");
          while (d.length > 0) {
            var cookieBase =
              encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
              "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
              d.join(".") +
              " ;path=";
            var p = window.location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
              document.cookie = cookieBase + p.join("/");
              p.pop();
            }
            d.shift();
          }
        }
      })();
      //window.location = "https://upro.century.ae";
      window.location.reload();
    } catch (e) {
      console.log(e.message);
    }
  }
  let fileNames = {
    "Most Traded Symbols": `Most Traded Symbols_${topSymbolsFilterForBarChart}_${platformType} By ${selectedMode}`,
    "Platform wise trade": `Platform wise trade_${platformWiseFilterForGraph}_${platformType} By ${selectedMode}`,
    "Filtered Traded Symbols": `Filtered Traded_Symbols ${symbolWiseFilterForGraph}_${platformType} By ${selectedMode}`
  }
  return (
    <div className="container" onClick={(e) => {
      const list = document.getElementById("Dropdown");
      try {
        let arr = ["All", ...clientList]
        if (e.target.id != "Dropdown" && e.target.id != "myInput") {
          let target = e.target.id;

          if (e.target.tagName == "DIV" || e.target.tagName == "LABEL") {
            target = target.substr(0, target.length - 1)
            if (target != "All") {
              target = Number(target)
            }
            if (!arr.includes(target)) {
              list.style.display = "none"
              setOpenDropDown(false)
              setTempList(["All"].concat(clientList))
            }
          }
          else {
            if (target != "All") {
              target = Number(target)
            }
            if (!arr.includes(target)) {
              list.style.display = "none"
              setOpenDropDown(false)
              setTempList(["All"].concat(clientList))
            }
          }
        }
      }
      catch (e) { }
      const listSymbol = document.getElementById("DropdownSymbol");
      let arrSymbol = ["All", ...symbolArray]
      if (e.target.id != "DropdownSymbol" && e.target.id != "myInputSymbol") {
        let target = e.target.id;
        if (e.target.tagName == "DIV" || e.target.tagName == "LABEL") {
          target = target.substr(0, target.length - 1)
          if (!arrSymbol.includes(target)) {
            listSymbol.style.display = "none"
            setOpenDropDownSymbol(false)
            setTempListSymbol(["All"].concat(symbolArray))
          }
        }
        else {
          if (!arrSymbol.includes(target)) {
            listSymbol.style.display = "none"
            setOpenDropDownSymbol(false)
            setTempListSymbol(["All"].concat(symbolArray))
          }
        }
      }
    }}>
      {(!isLoadingPlatformWiseData && !isLoadingSymbolData && !isLoadingTopSymbolData && !isLoadingStatData) && <div
        style={{
          height: "120vh",
          width: "100vw",
          position: "fixed",
          zIndex: "5",
          backgroundColor: "#fffffffa",
          backdropFilter: "blur(5px)",
          display: "block",
        }}
      ><h1>Loading...</h1>
        {/* <img src={loader} height={"500px"} width={"500px"} /> */}
      </div>}
      {<>
        <Dialog open={dialogOpen}>
          <DialogTitle>{"Added Symbol for Search"}</DialogTitle>
          <DialogContent>
            {addedSymbolArray.map(name => (
              <li>
                {name}
              </li>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setAddedSymbolArray([]); setDialogOpen(false); setDefaultValueSymbolFilter("All") }}>Clear Selection</Button>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              m="auto"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: "90%",
                  minHeight: "50px",
                  padding: "0 5%",
                },
              }}
            >
              <Paper sx={{ height: "auto", backgroundColor: "gold" }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h1 align="left">
                      {platformType == "TU" ? "Tradeultra " : "Century "} -
                      Management Dashboard{" "}
                    </h1>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      align="right"
                      style={{
                        float: "right",
                        height: "90px",
                        lineHeight: "90px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <h4
                        style={{
                          margin: "0",
                          marginRight: "10px",
                          float: "left",
                        }}
                      >
                        Welcome {user && user.name}
                      </h4>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "black",
                          height: "32px",
                          margin: "auto",
                        }}
                        onClick={() => {
                          logout();
                        }}
                      >
                        Logout
                      </Button>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        pl={"15px"}
                      >
                        <Typography style={{ fontWeight: 700 }}>
                          CENTURY
                        </Typography>
                        <AntSwitch
                          checked={platformType == "TU" ? true : false}
                          onChange={(checked) => {
                            togglePlatformType();
                          }}
                          inputProps={{ "aria-label": "ant design" }}
                        />
                        <Typography style={{ fontWeight: 700 }}>
                          TU
                        </Typography>
                      </Stack>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
              {<Paper
                sx={{
                  padding: "0 5%",
                  display: "flex",
                  height: "auto",
                  width: "90%",
                  backgroundColor: "#fafafa",
                  alignItems: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={5} xl={5} md={12} sm={12} xs={12}>
                    <Box
                      pt={2}
                      pb={2}
                      sx={{
                        boxSizing: "border-box",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          marginTop: "0",
                          textAlign: "left",
                        }}
                      >
                        <b>Please note:</b> We are constantly making
                        updates to the dashboard hence the data
                        represented may have minor differences.
                      </span>
                    </Box>
                  </Grid>
                  <Grid item lg={7} xl={7} md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        width: "100%",
                      }}
                    >
                      <Box
                        pt={2}
                        pb={2}
                        sx={{
                          display: "flex",
                          gap: 2,
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            lg={0}
                            xl={0}
                          >
                            <Box
                              sx={{
                                display: {
                                  lg: "none",
                                  xl: "none",
                                  md: "flex",
                                  sm: "flex",
                                  xs: "flex",
                                },
                                alignItems: "center",
                                gap: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  height: "1px",
                                  backgroundColor: "divider",
                                  flex: 1,
                                }}
                              ></Box>
                              <Box>Filters</Box>
                              <Box
                                sx={{
                                  height: "1px",
                                  backgroundColor: "divider",
                                  flex: 1,
                                }}
                              ></Box>
                            </Box>
                          </Grid>
                          <Grid item lg={2} xl={2} md={2} sm={6} xs={12}>
                            <FormControl sx={{ width: "100%" }}>
                              <Autocomplete
                                disablePortal
                                size="small"
                                id={"teamMember"}
                                defaultValue={"All"}
                                value={filterValues.current.user}
                                onChange={(e, option) => {
                                  handleValueChange({
                                    target: {
                                      name: "user",
                                      value: option,
                                    },
                                  });
                                }}
                                options={["All"].concat(emailList)}//emailList
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Team Member"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item lg={2} xl={2} md={2} sm={6} xs={12}>
                            <CustomMultiSelector openDropDown={openDropDown} setOpenDropDown={setOpenDropDown} setSelectedArray={setSelectedArray} selectedArray={selectedArray} data={clientList} tempList={(tempList)} setTempList={setTempList} />
                          </Grid>
                          <Grid item lg={2} xl={2} md={2} sm={6} xs={12}>
                            <CustomMultiSelector1 openDropDownSymbol={openDropDownSymbol} setOpenDropDownSymbol={setOpenDropDownSymbol} setSelectedArraySymbol={setSelectedArraySymbol} selectedArraySymbol={selectedArraySymbol} data={symbolArray} tempListSymbol={(tempListSymbol)} setTempListSymbol={setTempListSymbol} />
                          </Grid>

                          <Grid item lg={4} xl={4} md={6} sm={12} xs={12}>
                            <DateRangeTimePicker
                              state={dateRange}
                              setState={setDateRange}
                              filterValues={filterValues}
                            />
                          </Grid>

                          <Grid item lg={1} xl={1} md={3} sm={6} xs={12}>
                            <Button
                              fullWidth
                              variant="contained"
                              style={{ backgroundColor: "black" }}
                              onClick={
                                () => {
                                  console.log(filterValues.current)
                                  if (!selectedArray.includes("All") || selectedArraySymbol.includes("All") || filterValues.current.startDate != "" || filterValues.current.endDate != "" || filterValues.current.user != "All") {
                                    console.log("I m here")
                                    setFileReaderData(null);
                                    console.log(fileReaderData)
                                    getTxns()
                                  }
                                }
                              }
                            >
                              Submit
                            </Button>
                          </Grid>
                          <Grid item lg={1} xl={1} md={2} sm={6} xs={12}>
                            <Button
                              fullWidth
                              variant="outlined"
                              onClick={resetFilter}
                            >
                              Clear
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>}

            </Box>
          </Grid>
          <p style={{ width: "100%" }}>
            {
              <span style={{ background: "gold", padding: "0.5% 1%" }}>
                <b>
                  Dashboard has been updated with trading data from{" "}
                  {statistics.firstUpdatedDate && statistics.firstUpdatedDate[0].time.split("T")[0]} to{" "}
                  {statistics.lastUpdatedDate && statistics.lastUpdatedDate[0].time.split("T")[0]}
                </b>
              </span>
            }{" "}
            <span
              style={{
                float: "right",
                marginRight: "16px",
                background: "gold",
                padding: "0.5% 1%",
              }}
            >
              <b>Timezone: GMT +3</b>
            </span>
          </p>
          <Grid container spacing={2} paddingRight={"0.5%"} paddingLeft={"1%"}>
            <Grid item {...statCardGridSize}>
              <StatCard
                value={statistics.totalTrades ? statistics.totalTrades : 0}
                heading={"Total Trades"}
                infoKey={"Total trades"}
              />
            </Grid>
            <Grid item {...statCardGridSize}>
              <StatCard
                value={statistics.totalTradedSymbols ? statistics.totalTradedSymbols : 0}
                heading={"Total traded Symbols"}
                infoKey={"Total traded Symbols"}
              />
            </Grid>
            <Grid item {...statCardGridSize}>
              <StatCard
                value={statistics.totalClients ? statistics.totalClients : 0}
                heading={"Total Clients Traded"}
                infoKey={"Total Clients Traded"}
              />
            </Grid>
            <Grid item {...statCardGridSize}>
              <StatCard
                value={statistics.mostTradedSymbol ? statistics.mostTradedSymbol : 0}
                heading={"Top Traded Symbol"}
                infoKey={"Top Traded Symbol"}
              />
            </Grid >
          </Grid>
        </Grid >
        <Divider></Divider>
        <div style={{ padding: "5px", textAlign: "left", justifyContent: "space-between", display: "flex" }}>
          <span>
            <CustomButton id={"By Count"} selectedMode={selectedMode} displayString={"By Count"} customFunction={() => { ParentFilters("By Count") }} />
            <CustomButton selectedMode={selectedMode} displayString={"By Volume"} customFunction={() => { ParentFilters("By Volume") }} />
            <CustomButton selectedMode={selectedMode} displayString={"By Distinct User"} customFunction={() => { ParentFilters("By Distinct User") }} />
          </span>
          {<span style={{ display: "block" }}>
            <CustomButton selectedMode={selectedDisplayType} displayString={"By Percentage"} customFunction={() => { displayType("By Percentage") }} />
            <CustomButton selectedMode={selectedDisplayType} displayString={"By Value"} customFunction={() => { displayType("By Value") }} />
          </span>}
        </div>
        {<Grid
          container
          style={{ backgroundColor: "transparent", width: "100%" }}>
          <Grid item xs={12} sx={{ padding: "12px" }}>
            <Paper sx={{ minHeight: "500px", backgroundColor: "transparent", mt: 2 }}>
              <Item
                style={{
                  backgroundColor: "white",
                  fontWeight: "bold",
                  display: "flex",
                  flexFlow: "column",
                  p: "12px"
                }}
              >
                <div style={{ padding: "20px", textAlign: "right" }}>
                  <ComponentHeader Id={"platformwise"} heading={`Platform Wise Transaction ${selectedMode}`} />
                  <CustomButton selectedMode={platformWiseFilterForGraph} displayString={"Weekly"} customFunction={() => { switchLineData("Weekly") }} />
                  <CustomButton selectedMode={platformWiseFilterForGraph} displayString={"Monthly"} customFunction={() => { switchLineData("Monthly") }} />
                  <CustomButton selectedMode={platformWiseFilterForGraph} displayString={"Quarterly"} customFunction={() => { switchLineData("Quarterly") }} />
                  <CustomButton selectedMode={platformWiseFilterForGraph} displayString={"Yearly"} customFunction={() => { switchLineData("Yearly") }} />
                  <span style={{ float: "right", marginTop: "0px" }}>
                    <CSVLink
                      filename={fileNames["Platform wise trade"]}
                      data={lineChartValuesPlatform || []}
                    >
                      <SystemUpdateAltIcon sx={{ color: "black" }} />
                    </CSVLink>
                  </span>
                </div>
                <Grid item xs={12} p={2} sx={{ height: "500px" }}>
                  {isLoadingPlatformWiseData ? (lineChartValuesPlatform.length ? (selectedDisplayType == "By Percentage" ?
                    <CustomLineChart sx={{ height: "500px" }} selectedDisplayType={selectedDisplayType} data={lineChartValuesPlatform} /> :
                    <CustomBarComponent data={lineChartValuesPlatform} />) : <NoDataElement />) : <Loader />}
                </Grid>
              </Item>
            </Paper>

          </Grid>
          <Grid item xs={6} sx={{ padding: "12px" }}>
            <Paper sx={{ height: "auto", backgroundColor: "transparent", mt: 2, }}>
              <Item
                style={{
                  backgroundColor: "white",
                  fontWeight: "bold",
                  display: "flex",
                  flexFlow: "column",
                  p: 2
                }}
              >
                <div style={{ padding: "20px", textAlign: "right" }}>
                  <ComponentHeader Id={"topsymbols"} heading={`Top Symbols ${selectedMode}`} />
                  <CustomButton selectedMode={topSymbolsFilterForBarChart} displayString={"Last Week"} customFunction={() => { switchGraphData("Last Week") }} />
                  <CustomButton selectedMode={topSymbolsFilterForBarChart} displayString={"Last Month"} customFunction={() => { switchGraphData("Last Month") }} />
                  <CustomButton selectedMode={topSymbolsFilterForBarChart} displayString={"Last Quarter"} customFunction={() => { switchGraphData("Last Quarter") }} />
                  <CustomButton selectedMode={topSymbolsFilterForBarChart} displayString={"Last Year"} customFunction={() => { switchGraphData("Last Year") }} />
                  <span style={{ float: "right", marginTop: "0px" }}>
                    <CSVLink
                      filename={fileNames["Most Traded Symbols"]}
                      data={barChartForTopSymbols || []}
                    >
                      <SystemUpdateAltIcon sx={{ color: "black" }} />
                    </CSVLink>
                  </span>
                </div>
                <Grid item xs={12} p={3.75} sx={{ height: "400px" }}>
                  {isLoadingTopSymbolData ? (barChartForTopSymbols.length ? (<CustomBarChart data={barChartForTopSymbols} />) : <NoDataElement />) : <Loader />}
                </Grid>
              </Item>
            </Paper>
          </Grid>
          <Grid item xs={6} sx={{ padding: "12px" }}>
            <Paper sx={{ height: "auto", backgroundColor: "transparent", mt: 2 }}>
              <Item
                style={{
                  backgroundColor: "white",
                  fontWeight: "bold",
                  display: "flex",
                  flexFlow: "column",
                  p: 2
                }}
              >

                <Grid item lg={12} xl={12} md={12} sm={12} xs={12} sx={{ backgroundColor: "transparent", textAlign: "left", mt: 2, ml: 5 }}>
                  <ComponentHeader Id={"symbolwise"} heading={`Symbol Wise Transaction ${selectedMode}`} />
                </Grid>
                <div style={{ padding: "20px", textAlign: "right", display: "flex", justifyContent: "space-between" }}>
                  <Grid item lg={6} xl={6} md={2} sm={6} xs={6} sx={{ backgroundColor: "transparent", textAlign: "left" }}>
                    <FormControl sx={{ width: "30%", height: "20px !important" }}>
                      <Autocomplete
                        disablePortal
                        size="small"
                        defaultValue={defaultValueSymbolFilter}
                        onChange={(e, option) => {
                          setDefaultValueSymbolFilter(option)
                          if (option == "All") {
                            setAddedSymbolArray([])
                          }
                          else if (!addedSymbolArray.includes(option)) {
                            addedSymbolArray.push(option)
                          }
                        }}
                        options={["All"].concat(symbolArray)}
                        renderInput={(params) => (
                          <TextField {...params} label="Symbol" />
                        )}
                      />
                    </FormControl>
                    {"   "}
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#ffd700",
                        color: "black",
                        height: "32px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setDialogOpen(true)
                      }}
                    >
                      {"Show Added Symbols"}
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        height: "32px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        // setApplySymbolFilter(!applySymbolFilter)
                        lineGraphForSymbols(symbolWiseFilterForGraph);
                      }}
                    >
                      {"Apply"}
                    </Button>
                  </Grid>
                  <Grid>
                    <CustomButton selectedMode={symbolWiseFilterForGraph} displayString={"Weekly"} customFunction={() => { switchLineDataForsymbol("Weekly") }} />
                    <CustomButton selectedMode={symbolWiseFilterForGraph} displayString={"Monthly"} customFunction={() => { switchLineDataForsymbol("Monthly") }} />
                    <CustomButton selectedMode={symbolWiseFilterForGraph} displayString={"Quarterly"} customFunction={() => { switchLineDataForsymbol("Quarterly") }} />
                    <CustomButton selectedMode={symbolWiseFilterForGraph} displayString={"Yearly"} customFunction={() => { switchLineDataForsymbol("Yearly") }} />
                  </Grid>

                </div>
                <span style={{ float: "right", marginRight: "24px", textAlign: "right" }}>
                  <CSVLink
                    filename={fileNames["Filtered Traded Symbols"]}
                    data={lineChartForSymbol || []}
                  >
                    <SystemUpdateAltIcon sx={{ color: "black" }} />
                  </CSVLink>
                </span>
                <Grid item xs={12} p={2} sx={{ height: "383px" }}>
                  {isLoadingSymbolData ? (lineChartForSymbol.length ? (<CustomLineChart sx={{ height: "383px" }} data={lineChartForSymbol} selectedDisplayType={selectedDisplayType} />) : <NoDataElement />) : <Loader sx={{ height: "383px" }} />}
                </Grid>
              </Item>
            </Paper>
          </Grid>
        </Grid>}

      </>
      }
    </div >
  );
}
function Loader() {
  return <img src={loader} height={"383px"} width={"500px"} />
}

function ComponentHeader(props) {
  const { Id, heading } = props
  return <Typography sx={{
    display: "flex", flexDirection: "row", justifyContent: "center", fontSize: "24px", fontWeight: "w500",
    mb: 2,
  }}><span id={Id}
  // style={{ padding: "5px", border: "1px solid #ffd700", borderRadius: "5px" }}
  // onMouseOverCapture={() => {
  //   document.getElementById(Id).style.backgroundColor = "#ffd700"
  //   document.getElementById(Id).style.fontWeight = "500"
  //   document.getElementById(Id).style.cursor = "pointer"
  // }}
  // onMouseLeave={() => {
  //   document.getElementById(Id).style.backgroundColor = "transparent"
  //   document.getElementById(Id).style.fontWeight = "normal"

  // }}
  >{heading}</span></Typography>
}
function CustomButton(props) {
  const { selectedMode, customFunction, displayString } = props;
  return <Button
    variant="contained"
    style={{
      backgroundColor: selectedMode == displayString ? "#ffd700" : "black",
      color: selectedMode == displayString ? "black" : "white",
      height: "32px",
      marginRight: "10px",
    }}
    onClick={() => {
      customFunction()
    }}
  >
    {displayString}
  </Button>
}
function mouseActions(Id) {
  const onMouseOverCapture = () => {
    document.getElementById(Id).style.backgroundColor = "#ffd700"
    document.getElementById(Id).style.fontWeight = "500"
    document.getElementById(Id).style.cursor = "pointer"
  }
  const onMouseLeave = () => {
    document.getElementById(Id).style.backgroundColor = "transparent"
    document.getElementById(Id).style.fontWeight = "normal"

  }
  return {
    onMouseOverCapture,
    onMouseLeave
  }
}
const CustomBarComponent = (props) => {
  const data = props.data;
  function getFormattedStatValue(value) {
    let val = null;
    value = parseFloat(value)

    if (value < 1000) {
      val = value
    } else if (value >= 1000 && value < 1000000) {
      val = Math.round(value / 1000).toLocaleString() + "K";
    } else if (value >= 1000000) {
      val = Math.round(value / 1000000).toLocaleString() + "M";

    }
    return val;
  }
  const config = {
    data: data,
    isStack: true,
    xField: 'item',
    yField: 'value',
    seriesField: 'type',
    label: {
      style: { fill: "black" },
      formatter: (label) => {
        return getFormattedStatValue(label.value);
      },
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    yAxis: {
      grid: { line: { style: 0 } },
      label: {
        formatter: (label) => {
          return getFormattedStatValue(label);
        },
      },
    },
    color: ["#e8f2d8", "#ffd700", "#d5afdd", "black", "#fbd4d9", "#373A36"],
    interactions: [
      {
        type: "element-highlight",
      },
    ],

    legend: { position: "top" },
  };
  return <Column {...config} />;
};
function NoDataElement() {
  return (
    <>
      <br />
      <br />
      <br />
      <h2 style={{ color: "goldenrod", height: "300px" }}>No Data Available</h2>
    </>
  );
}

function InfoPopover(props) {
  const { content, color = "black" } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton aria-describedby={id} size="small" onClick={handleClick}>
        <InfoOutlinedIcon size="small" sx={{ color }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>{content}</Typography>
      </Popover>
    </>
  );
}
