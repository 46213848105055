import { Dropdown } from "antd";
import React, { useState, useEffect } from "react";
export default function CustomMultiSelector(props) {
    const { data, setSelectedArray, selectedArray, tempList, setTempList, openDropDown, setOpenDropDown } = props;
    // const [openDropDown, setOpenDropDown] = useState(false);
    function areEqual(arr1, arr2) {
        let N = arr1.length;
        let M = arr2.length;
        if (N != M)
            return false;
        arr1.sort();
        arr2.sort();
        for (let i = 0; i < N; i++)
            if (arr1[i] != arr2[i])
                return false;
        return true;
    }
    const dropDownAction = () => {
        setOpenDropDown(!openDropDown);
    };
    function myFunction() {
        let searchInput = document.getElementById("myInput")
        if (searchInput != null) {
            searchInput = searchInput.value;
            if (searchInput.length == 0) {
                setTempList(["All"].concat(data))
            }
            else {
                let temp = []
                for (let i = 0; i < data.length; ++i) {
                    if (data[i].toString().includes(searchInput)) {
                        temp.push(data[i])
                    }
                }
                setTempList(temp)
            }
        }
        else {
            setTempList(data)

        }
    }

    useEffect(() => {
    }, [])
    return <div style={{ zIndex: "1", marginTop: "-8px" }} id={"main"}>
        {
            selectedArray != -1 &&
            <>
                <fieldset style={{ width: "130px", borderRadius: "5px", borderColor: "white" }} onClick={dropDownAction}>
                    <legend style={{ color: "lightslategrey", fontSize: "14px" }}>Clients</legend>
                    <div
                        style={{ width: "130px", height: "21px" }}>
                        <p id={"dropdownview"} style={{ marginTop: "-9px", marginLeft: "5px", textAlign: "left" }}>All</p>
                    </div>
                </fieldset>
            </>
        }
        {openDropDown && <div id={"Dropdown"} style={{
            boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)", borderRadius: "8px",
            zIndex: "10", maxHeight: "250px", width: "180px", marginTop: "4px", position: "absolute", overflowY: "scroll", backgroundColor: "white", display: "flex", alignItems: "start", flexDirection: "column"
        }}>
            <input type="text" placeholder="Search" name="Search Client"
                id="myInput" onChange={() => { myFunction() }}
                style={{ width: "136px", height: "38px", border: "1px solid #dbdad9", borderRadius: "3px", lineHeight: "48px", alignSelf: "center", marginBottom: "4px", marginTop: "4px" }}>
            </input>
            {
                tempList.length != 0 ?
                    (tempList).map((id, index) => {
                        let divId = id + "1"
                        let labelId = id + "2"//beacause unable to use class
                        return <div id={divId} onMouseOverCapture={() => {
                            document.getElementById(divId).style.backgroundColor = "#ffd700"
                            document.getElementById(divId).style.fontWeight = "500"
                            document.getElementById(divId).style.cursor = "pointer"
                        }}
                            onMouseLeave={() => {
                                document.getElementById(divId).style.backgroundColor = "transparent"
                                document.getElementById(divId).style.fontWeight = "normal"
                            }}
                            style={{
                                height: "44px", backgroundColor: "", marginLeft: "0", padding: "5px 10px", width: "140px", textAlign: "left"
                            }} >
                            <input type="checkbox" name={id} value={id}
                                checked={selectedArray[0] == "All" ? true : selectedArray.indexOf(id) > -1}
                                id={id}
                                onClick={() => {
                                    var isChecked = document.getElementById(id).checked;
                                    if (isChecked) {
                                        if (index == 0 && tempList[0] == "All") {
                                            document.getElementById("dropdownview").innerHTML = "All"
                                            setSelectedArray(["All"].concat(tempList))
                                        }
                                        else {
                                            if (data.length - 1 == selectedArray.length) {
                                                document.getElementById("dropdownview").innerHTML = "All"
                                                setSelectedArray(["All"].concat(tempList))
                                            }
                                            else {
                                                document.getElementById("dropdownview").innerHTML = "Select"
                                                setSelectedArray((old) => {
                                                    let temp = [...old]
                                                    temp.push(id)
                                                    return temp;
                                                })
                                            }
                                        }
                                    }
                                    else {
                                        if (index == 0) {
                                            setSelectedArray([]);
                                            document.getElementById("dropdownview").innerHTML = "Select"
                                        }
                                        else {
                                            if (areEqual(["All"].concat(tempList), selectedArray)) {
                                                document.getElementById("dropdownview").innerHTML = "Select"
                                                let temp = selectedArray.filter(function (e) { return e !== "All" & e != id })
                                                setSelectedArray(temp)
                                            }
                                            else {
                                                let temp = selectedArray.filter(function (e) { return e !== id })
                                                console.log(temp)
                                                setSelectedArray(temp)
                                            }

                                        }
                                    }
                                }} />
                            <label for={id} id={labelId}>{id}</label>
                        </div>
                    }) : <p>List Empty</p>
            }
        </div>}
    </div>
}