const DashboardTooltipItems = {
  topCRs: "Top Relation managers",
  totalTxns: "Total Transactions made in the given period",
  totalClients: "Total Clients in Tradeultra",
  totalActiveClients:
    "Total Clients that have transacted at least once in the given period",
  totalTradeVolume:
    "Total Trade Volume across all transactions in the given period",
  totalInstrumentsTraded: "Total unique Symbols traded in the given period",
  totalDeposits: "Total deposited amount in the given period",
  totalWithdrawals: "Total amount withdrawn in the given period",
  totalTxnVolume:
    "Total Trade Volume across all transactions in the given period",
  totalCommissions: "Total Commissions earned in the given period",
  totalSpread: "Total Spread earned in the given period",
  monthlyTxnSummary: "Month on Month total transaction count and volumes",
  monthlyTotalTxnSummary: "Month on month total transaction count and volumes",
  monthlyAvgTxnSummary:
    "Month on month average transaction value and total count",
  monthlyDepositsAndWithdrawals: "Month on month funding report",
  monthlyDepositsAndCount:
    "Month on month count of deposits and their total amount",
  creditSummary: "Credit summary",
  monthWiseCreditHistory: "Month on month credit report",
  topClients: "Top Client as per given buckets",
  clientBehaviour:
    "Client behaviour in terms total numbers of transactions made",
  comsAndSpreadsMonthly: "Your Monthly Commissions and Spread Totals",
  monthlyAvgRevenuePerClient: "",
  revenueAsMeasure: "",
  symbolWiseTxns: "Symbol wise total count of transactions",
  actVsPassClients:
    "Your total vs actively trading clients in the period selected",
  fundingStats: "Total Funding Stats",
  fundingTxns: "Funding Transactions",
  totalHoldingCost: "Marked up holding cost received from clients",
  monthlyRevenue: "Monthly holding cost, commission, Spread generated from clients",
  txnDump: "",
};

export default DashboardTooltipItems;
